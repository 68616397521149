<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <select-yes-no-radio name="ownedByAnotherBusiness" v-model="ownedByAnother">Entity is owned by another business?</select-yes-no-radio>
      
      <base-input name="dba"
        label="Trade Name / DBA (optional)"
        opt-class="mb-4"
        v-model="tradeNameOrDBA"
      />

      <div class="d-flex justify-content-end">
        <button class="button btn-primary" @click.prevent="handleNext">Next</button>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: String,
  },
  computed: {
    ownedByAnother: {
      get() {
        return store.state.forms.FormOrgInfo.ownedByAnother
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.ownedByAnother', value})
      }
    },
    tradeNameOrDBA: {
      get() {
        return store.state.forms.FormOrgInfo.tradeNameOrDBA
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.tradeNameOrDBA', value})
      }
    }
  }
}
</script>
