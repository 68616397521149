<template>
  <div :class="optClass">
    <label :for="name" class="form-label"><slot>State</slot></label>
    <select class="form-control" :id="name" @change="change" :value="value">
      <option value="">Select an option</option>
      <option value="AK">Alaska</option>
      <option value="CA">California</option>
      <option value="CT">Connecticut</option>
      <option value="GA">Georgia</option>
      <option value="LA">Louisiana</option>
      <option value="MO">Missouri</option>
      <option value="NV">Nevada</option>
      <option value="NM">New Mexico</option>
      <option value="SC">South Carolina</option>
      <option value="WA">Washington</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'state'
    },
    optClass: {
      type: String,
      default: 'mb-3'
    },
    value: {
      type: String
    }
  },
}
</script>
