<template>
  <div :class="optClass">
    <label :for="generatedId" class="form-label"><slot>Select Your Business Entity Type</slot></label>
    <select
      class="form-control"
      :id="generatedId"
      @change="change"
      :value="value"
      v-if="entityType==='c-corp' || entityType==='s-corp' || entityType==='b-corp' || entityType==='close-corp' || entityType==='nonprofit' || !entityType"
    >
      <option value="">Select an option</option>
      <option value="Inc.">Inc.</option>
      <option value="Co.">Co.</option>
      <option value="Corp.">Corp.</option>
      <option value="Ltd.">Ltd.</option>
      <option value="Incorporated">Incorporated</option>
      <option value="Company">Company</option>
      <option value="Corporation">Corporation</option>
      <option value="Limited">Limited</option>
    </select>

    <select
      class="form-control"
      :id="generatedId"
      @change="change"
      :value="value"
      v-if="entityType==='pcorp'"
    >
      <option value="">Select an option</option>
      <option value="PC">PC</option>
      <option value="P.C.">P.C.</option>
      <option value="Professional Corporation">Professional Corporation</option>
    </select>

    <select
      class="form-control"
      :id="generatedId"
      @change="change"
      :value="value"
      v-if="entityType==='pllc'"
    >
      <option value="">Select an option</option>
      <option value="PLLC">PLLC</option>
      <option value="P.L.L.C">P.L.L.C</option>
      <option value="Professional LLC">Professional LLC</option>
      <option value="Professional L.L.C">Professional L.L.C.</option>
      <option value="Professional Limited Liability Company">Professional Limited Liability Company</option>
    </select>

    <select
      class="form-control"
      :id="generatedId"
      @change="change"
      :value="value"
      v-if="entityType==='lp' || entityType==='gp'"
    >
      <option value="">Select an option</option>
      <option value="LP">LP</option>
      <option value="L.P.">L.P.</option>
      <option value="Ltd">Ltd</option>
      <option value="Limited">Limited</option>
      <option value="Limited Partnership">Limited Partnership</option>
    </select>

    <select
      class="form-control"
      :id="generatedId"
      @change="change"
      :value="value"
      v-if="entityType==='llp'"
    >
      <option value="">Select an option</option>
      <option value="LLP">LLP</option>
      <option value="L.L.P.">L.L.P.</option>
      <option value="Limited Liability Partnership">Limited Liability Partnership</option>
    </select>

    <select
      class="form-control"
      :id="generatedId"
      @change="change"
      :value="value"
      v-if="entityType==='lllp'"
    >
      <option value="">Select an option</option>
      <option value="LLLP">LLLP</option>
      <option value="L.L.L.P.">L.L.L.P</option>
      <option value="Limited Liability Limited Partnership">Limited Liability Limited Partnership</option>
    </select>

    <select
      class="form-control"
      :id="generatedId"
      @change="change"
      :value="value"
      v-if="entityType==='llc' || entityType==='nonprofit-llc'"
    >
      <option value="">Select an option</option>
      <option value="LLC">LLC</option>
      <option value="L.L.C">L.L.C</option>
      <option value="LC">LC</option>
      <option value="L.C.">L.C.</option>
      <option value="Ltd.Co.">Ltd.Co.</option>
      <option value="Ltd Company">Ltd Company</option>
      <option value="Ltd. Liability Co.">Ltd. Liability Co.</option>
      <option value="Ltd. Liability Company">Ltd. Liability Company</option>
      <option value="Limited Co.">Limited Co.</option>
      <option value="Limited Company">Limited Company</option><br>
      <option value="Limited Liability Co.">Limited Liability Co.</option>
      <option value="Limited Liability Company">Limited Liability Company</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'designator'
    },
    optClass: {
      type: String,
      default: 'mb-2'
    },
    entityType: {
      type: String,
    },
    value: String
  },
}
</script>