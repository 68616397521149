<template>
  <b-tab :active="active">
    <template #title>
      <feather-icon
        v-if="icon!==''"
        :icon="icon"
        size="18"
        class="mr-50"
      />
      <span class="font-weight-bold">{{ tabTitle }}</span>
    </template>
    <b-card>
      <slot />
    </b-card>
  </b-tab>
</template>

<script>
import { BTab, BCard } from 'bootstrap-vue'

export default {
  components: {
    BTab,
    BCard,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    tabTitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>
