<template>
  <div :class="optClass">
    <slot name="label">
      <label :for="name" class="form-label">{{label}}</label>
    </slot>
    <slot name="description">
    </slot>
    <input
      class="form-control"
      :type="type"
      :id="name"
      :name="name"
      :value="value"
      :placeholder="placeholder"
      v-on="handlers"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    label: String,
    value: String | Number,
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    optClass: {
      type: String,
      default: 'mb-1'
    },
    required: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    handlers() {
      return {
        input: this.handleInput,
        focus: this.handleFocus,
        blur: this.handleBlur
      }
    },
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
    handleFocus(e) {
      this.focused = true
      this.$emit('focus', e)
    },
    handleBlur(e) {
      this.focused = false
      this.$emit('blur', e)
    }
  }
}
</script>
