export default ({$http}) => ({
  fetchRecords: (queryParams) => {
    return new Promise((resolve, reject) => {
      $http
        .get('/admin/process-records', {params: queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
})
