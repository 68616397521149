<template>
  <div :class="optClass">
    <label :for="generatedId" class="form-label"><slot>Country</slot></label>
    <select class="form-control" :id="generatedId" @change="change" :value=value>
      <option value="">select an option</option>
      <option value="us">United States</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'country'
    },
    optClass: {
      type: String,
      default: 'mb-1'
    },
    value: {
      type: String
    }
  },
}
</script>