<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-2">
        <p>Enter the physical address of your company including the suite number if applicable. The company address can be anywhere in the U.S. but it <b>cannot be a P.O Box.</b> If you run a home based business, you may enter your home address.</p>
      </div>

      <base-input
        name="address"
        label="Address"
        v-model="bizAddress"
        required
      />
      
      <base-input
        name="address1"
        label="Apt, suite, unit, etc"
        v-model="bizAddress1"
        required
      />

      <base-input
        name="city"
        label="City"
        v-model="bizCity"
        required
      />

      <select-state name="state" v-model="bizState" />

      <base-input
        name="zip"
        label="ZIP"
        type="number"
        opt-class="mb-2"
        v-model="bizZip"
        required
      />

      <!-- Mailing address is different? -->
      <div class="mb-2 d-flex align-items-start">
        <label class="form-check-label">
          <input type="checkbox"  name="bizHasDifferentMailingAddress" value="bizHasDifferentMailingAddress" class="mr-2 mt-2" v-model="bizHasDifferentMailingAddress">
          Mailing address is different?
        </label>
      </div>

      <div v-show="bizHasDifferentMailingAddress">
        <!-- Mailing address input? -->      
        <div class="my-2">
          <h2 class="title ff-arial fs-24 fw-500">Mailing Address</h2>
        </div>

        <div class="mb-2">
          <p>Enter the mailing address including the suite number if applicable. The mailing address can be anywhere in the U.S. If you run a home based business, you may enter your home address.</p>
        </div>

        <base-input
          name="mail-address"
          label="Address"
          v-model="bizMailAddress"
          required
        />
        
        <base-input
          name="mail-address1"
          label="Apt, suite, unit, etc"
          v-model="bizMailAddress1"
          required
        />

        <base-input
          name="mail-city"
          label="City"
          v-model="bizMailCity"
          required
        />

        <select-state name="mail-state" v-model="bizMailState" />

        <base-input
          name="mail-zip"
          label="ZIP"
          type="number"
          opt-class="mb-4"
          v-model="bizMailZip"
          required
        />
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: String
  },
  computed: {
    bizAddress: {
      get() {
        return store.state.forms.FormBizAddress.address
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.address', value})
      }
    },
    bizAddress1: {
      get() {
        return store.state.forms.FormBizAddress.address1
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.address1', value})
      }
    },
    bizCity: {
      get() {
        return store.state.forms.FormBizAddress.city
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.city', value})
      }
    },
    bizState: {
      get() {
        return store.state.forms.FormBizAddress.state
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.state', value})
      }
    },
    bizZip: {
      get() {
        return store.state.forms.FormBizAddress.zip
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.zip', value})
      }
    },
    bizHasDifferentMailingAddress: {
      get() {
        return store.state.forms.FormBizAddress.hasDifferentMailingAddress
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.hasDifferentMailingAddress', value})
      }
    },
    bizMailAddress: {
      get() {
        return store.state.forms.FormBizAddress.mailAddress
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.mailAddress', value})
      }
    },
    bizMailAddress1: {
      get() {
        return store.state.forms.FormBizAddress.mailAddress1
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.mailAddress1', value})
      }
    },
    bizMailCity: {
      get() {
        return store.state.forms.FormBizAddress.mailCity
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.mailCity', value})
      }
    },
    bizMailState: {
      get() {
        return store.state.forms.FormBizAddress.mailState
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.mailState', value})
      }
    },
    bizMailZip: {
      get() {
        return store.state.forms.FormBizAddress.mailZip
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizAddress.mailZip', value})
      }
    },
  }
}
</script>