<template>
  <div :class="optClass">
    <!-- Question content -->
    <p class="mb-0">
      <strong>
        <slot></slot>
      </strong>
    </p>
    <!-- yes, no radios -->
    <div class="d-flex align-items-center">
      <label class="d-flex align-items-center mr-3" :for="idOfYes">
        <input type="radio" :name="name" :id="idOfYes" class="mr-2" @click="$emit('input', true)" :checked="value">
        <span>Yes</span>
      </label>
      <label class="d-flex align-items-center" :for="idOfNo">
        <input type="radio" :name="name" :id="idOfNo" class="mr-2" @click="$emit('input', false)" :checked="value===false">
        <span>No</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optClass: {
      type: String,
      default: 'mb-1'
    },
    name: String,
    value: Boolean
  },
  computed: {
    idOfYes() { return `${this.name}Yes` },
    idOfNo() { return `${this.name}No` }
  }
}
</script>
