<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">State Certified Copy</h2>
    </div>
    <form>
      <div class="mb-3">
        <p>Please select the document type you would like for us to obtain from the Secretary of State.</p>
      </div>

      <div class="mb-2">
        <label for="document-type" class="form-label">What document would you like to order certified copies of ?</label>
        <select class="form-control" id="document-type" v-model="documentType">
          <option value="">Select an option</option>
          <option value="Article of Incoporation (Organization/Formation)">Article of Incoporation (Organization/Formation)</option>
          <option value="Certificate of Amendment">Certificate of Amendment</option>
          <option value="Certificate of Dissolution">Certificate of Dissolution</option>
          <option value="Filed Annual Report">Filed Annual Report</option>
          <option value="Filed Initial Report">Filed Initial Report</option>
          <option value="Filed a DBA">Filed a DBA</option>
        </select>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    documentType: {
      get() {
        return store.state.forms.FormCertifiedCopy.documentType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCertifiedCopy.documentType', value})
      }
    },
  }
}
</script>
