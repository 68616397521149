<template>
  <div>
    <validation-observer
      #default="{handleSubmit}"
      ref="refFormObserver"
      slim
    >
      <b-card>
        <b-card-title>Attach file</b-card-title>
        <validation-provider #default="validationContext" name="Description" rules="required">
          <b-form-textarea 
            rows="3" 
            placeholder="Please leave a description here..."
            class="mb-1"
            :disabled="attachmentData.uploading"
            :state="getValidationState(validationContext)"
            v-model="attachmentData.description"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{validationContext.errors[0]}}
          </b-form-invalid-feedback>
        </validation-provider>

        <div class="form-row mt-1">
          <b-col cols="8">
            <validation-provider #default="validationContext" name="File" rules="required">
              <b-form-file
                placeholder="Choose a file or drop it here... (Max: 4MB)" 
                drop-placeholder="Drop file here..."
                :disabled="attachmentData.uploading"
                :state="getValidationState(validationContext)"
                v-model="attachmentData.file"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{validationContext.errors[0]}}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col cols="4">
            <b-button variant="primary" class="float-right" :disabled="attachmentData.uploading" @click.prevent="handleSubmit(onSubmit)">Upload</b-button>
          </b-col>
        </div>
      </b-card>
    </validation-observer>

    <b-card>
      <b-card-title>
        Attachments
      </b-card-title>
      <b-row>
        <b-table
          show-empty
          empty-text="No attachments yet."
          :fields="tableColumns"
          :items="$store.state.forms.submission.attachments"
        >
          <template #cell(user)="data">
            {{data.item.user.email}}
          </template>
          <template #cell(createdAt)="data">
            {{ new Date(data.item.createdAt).toLocaleString() }}
          </template>
          <template #cell(url)="data">
            <b-link
              target="_blank"
              :href="data.item.url"
            >
              View
            </b-link>
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BRow, BCol, BCard, BCardBody, BCardTitle, BCardText, BFormFile, BFormTextarea, BTable, BButton, BLink, BFormInvalidFeedback } from 'bootstrap-vue'
import applyS3DirectUpload from '@/common/AttachmentUpload.js'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BFormFile,
    BFormTextarea,
    BTable,
    BButton,
    BLink,
    BFormInvalidFeedback,

    ToastificationContent
  },
  setup() {
    const toast = useToast()
    const tableColumns = [
      {key: 'fileName',     label: 'File Name', sortable: true},
      {key: 'user',         label: 'Attached By', sortable: true},
      {key: 'description',  label: 'Description', sortable: false},
      {key: 'createdAt',    label: 'Uploaded At', sortable: true},
      {key: 'url',          label: 'File', sortable: false}
    ]

    const blankAttachmentData = {
      uploading: false,
      file: null,
      description: ''
    }
    const attachmentData = ref({...blankAttachmentData})
    const resetAttachmentData = () => {
      attachmentData.value = {...blankAttachmentData}
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetAttachmentData)

    const onSubmit = async () => {
      if (attachmentData.value.file.size > 4096 * 1024) {
        return toast({
          component: ToastificationContent,
          props: {
            title: 'File size is limited under 4MB!',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      }

      attachmentData.value.uploading = true
      let uploadedResult = await applyS3DirectUpload(attachmentData.value.file)
      attachmentData.value.uploading = false

      if (!uploadedResult) {
        return toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to upload file!',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      }

      store
        .dispatch(
          'forms/attachFile', 
          {
            fileName: attachmentData.value.file.name, 
            description: attachmentData.value.description,
            url: uploadedResult
          })
        .then(() => {
          resetForm()
          toast({
            component: ToastificationContent,
            props: {
              title: 'File Uploaded',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Failed Upload',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      tableColumns,
      attachmentData,

      refFormObserver,
      getValidationState,
      resetForm,

      onSubmit
    }
  }
}
</script>
