import { render, staticRenderFns } from "./FormRegisteredAgent.vue?vue&type=template&id=527e5def&scoped=true&"
import script from "./FormRegisteredAgent.vue?vue&type=script&lang=js&"
export * from "./FormRegisteredAgent.vue?vue&type=script&lang=js&"
import style0 from "./FormRegisteredAgent.vue?vue&type=style&index=0&id=527e5def&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "527e5def",
  null
  
)

export default component.exports