<template>
  <div>
    <validation-observer
      #default="{handleSubmit}"
      ref="refFormObserver"
    slim>
      <b-card>
        <b-card-title>Submit new updates</b-card-title>
        <div class="mt-1">
          <validation-provider #default="validationContext" name="Comment" rules="required">
            <b-form-textarea
              rows="3"
              placeholder="Please leave a comment here..."
              :state="getValidationState(validationContext)"
              v-model="recordData.message"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{validationContext.errors[0]}}
            </b-form-invalid-feedback>
          </validation-provider>

          <b-row class="mt-1">
            <b-col cols="4">
              <validation-provider #default="validationContext" name="Action" rules="required">
                <v-select
                  v-model="recordData.action"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="record-type"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{validationContext.errors[0]}}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col cols="4">
            </b-col>
            <b-col cols="4">
              <b-button variant="primary" class="float-right" @click.prevent="handleSubmit(onSubmit)">Submit</b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </validation-observer>

    <process-records :records="$store.state.forms.submission.processRecords" />
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { BRow, BCol, BCard, BCardTitle, BMedia, BFormTextarea, BButton, BFormInvalidFeedback } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ProcessRecords from './SubmissionProcessRecords.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BMedia,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    vSelect,

    ToastificationContent,
    ProcessRecords
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const toast = useToast()
    const typeOptions =[
      { value: '',          label: 'Select an option' },
      { value: 'accepted',  label: '1. Accept' },
      { value: 'approved',  label: '2. Approve' },
      { value: 'submitted', label: '3. Submit' },
      { value: 'completed', label: '4. Complete' },
      { value: 'memo',      label: 'Memo' },
    ]

    const blankRecordData = {
      message: '',
      action: ''
    }
    const recordData = ref({...blankRecordData})
    const resetRecordData = () => {
      recordData.value = {...blankRecordData}
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetRecordData)

    const onSubmit = () => {
      store
        .dispatch('forms/submitProcessRecord', recordData.value)
        .then(() => {
          resetForm()
          toast({
            component: ToastificationContent,
            props: {
              title: 'Records Updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Failed Updates',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      typeOptions,
      recordData,

      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
