<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-2">
        <p class="mb-0"><strong>How is your LLC managed?</strong></p>
        <label class="d-flex align-items-center mr-3 mb-0" for="by-member">
          <input type="radio" name="management-type" id="by-member" class="mr-2" @click="handleCheck('member')" :checked="$store.state.forms.FormOrgMemberType==='member'">
          <span>The LLC is managed by member(s)</span>
        </label>
        <label class="d-flex align-items-center mr-3 mb-0" for="by-manager">
          <input type="radio" name="management-type" id="by-manager" class="mr-2" @click="handleCheck('manager')" :checked="$store.state.forms.FormOrgMemberType==='manager'">
          <span>The LLC is managed by manager(s)</span>
        </label>  
      </div>

      <member-info-input
        v-for="id in countRange"
        :type="memberTypeCapitalized"
        :key="id"
        :id="id"
        @add="addMember"
        @delete="deleteMember"
      />
    </form>
  </div>
</template>

<script>
import range from 'lodash.range'
import capitalize from 'lodash.capitalize'
import store from '@/store'

export default {
  props: {
    title: String
  },
  methods: {
    handleCheck(v) {
      store.commit('forms/CHANGE_ORG_ALL_MEMBER_TYPE', v)
    },
    deleteMember(id) {
      store.commit(
        'forms/DELETE_ITEM_IN_FORM_ARRAY',
        {
          type: 'FormOrgMember',
          at: id
        }
      )
    },
    addMember() {
      store.commit(
        'forms/ADD_NEW_ITEM_IN_FORM_ARRAY', 
        {
          type: 'FormOrgMember',
          additional: {
            memberType: store.state.forms.FormOrgMemberType
          }
        }
      )
    }
  },
  computed: {
    countRange() {
      return range(store.state.forms.FormOrgMember.length)
    },
    memberTypeCapitalized() {
      return capitalize(store.state.forms.FormOrgMemberType)
    },
  },
  mounted() {
    store.commit('forms/CHANGE_ORG_ALL_MEMBER_TYPE', store.state.forms.FormOrgMemberType)
  }
}
</script>