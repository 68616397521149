<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Website for Your Company</h2>
    </div>

    <div class="mb-1">
      <p class="mb-1">Do you need a website?  We can help you set one up as soon as possible!</p>
      <p>Why you need a website? A website offers a wide variety of benefits for your business and most of these benefits increase in value exponentially year after year. Let us help you get started today!</p>
    </div>

    <div class="mb-1">
      <label><strong>What comes with the project?</strong></label>
      <p class="mb-1">Stand out from your competitors with a custom website created with conversions in mind. We understand that your website needs to look great, load fast, and most importantly convert your visitors into sales and leads for your business.</p>
      <ul id="webservice-package-list" class="ml-4">
        <li>Website Hosting (setup)</li>
        <li>Domain Name (setup)</li>
        <li>Website Daily Back-Up</li>
        <li>Website Redesigns</li>
        <li>Mobile-Friendly Responsive</li>
        <li>Website Security</li>
        <li>Content Writing</li>
        <li>WordPress CMS</li>
        <li>Unlimited Revisions</li>
        <li>Custom Website Development</li>
        <li>Website Maintenance</li>
        <li>WordPress Design &amp; Development</li>
      </ul>
    </div>

    <form>
      <div class="mb-1">
        <p class="mb-0"><strong>Please choose a plan for your website.</strong></p>
        <label class="d-flex align-items-center mb-0">
          <input type="radio" name="web-service-plan" id="web-service-plan-smb" class="mr-2" @click="handleSelect('smb')" :checked="$store.state.forms.FormWebDevelopmentInfo.packageType==='smb'">
          <span>Small Business (10-20 pages): <strong>$1499.99</strong></span>
        </label>
        <label class="d-flex align-items-center mb-0">
          <input type="radio" name="web-service-plan" id="web-service-plan-professional" class="mr-2" @click="handleSelect('professional')" :checked="$store.state.forms.FormWebDevelopmentInfo.packageType==='professional'">
          <p class="my-0">
            <span>Professional (20 pages): <strong>$1999.99</strong></span>
            <span class="ml-3 badge badge-success">BEST SELLER</span>
          </p>
        </label>
        <label class="d-flex align-items-center mb-0">
          <input type="radio" name="web-service-plan" id="web-service-plan-enterprise" class="mr-2" @click="handleSelect('enterprise')" :checked="$store.state.forms.FormWebDevelopmentInfo.packageType==='enterprise'">
          <span>Enterprise (20+ pages): <strong>$2499.99</strong></span>
        </label>
        <label class="d-flex align-items-center">
          <input type="radio" name="web-service-plan" id="web-service-plan-no" class="mr-2" @click="handleSelect('')" :checked="$store.state.forms.FormWebDevelopmentInfo.packageType===''">
          <span>No, Thank you</span>
        </label>  
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  methods: {
    handleSelect(value) {
      store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.packageType', value})
    }
  }
}
</script>

<style lang="scss" scoped>
ul#webservice-package-list {
  list-style: disc;
}
</style>
