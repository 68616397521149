<template>
  <b-card>
    <b-card-title>
      Processing Records
    </b-card-title>
    <app-timeline>
      <app-timeline-item 
        v-for="record in records"
        :key="record._id"
        :variant="resolveProcessRecordVariant(record.action)"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
          <h6>{{capitalize(record.action)}}</h6>
          <small class="text-muted">{{new Date(record.createdAt).toLocaleString()}}</small>
        </div>
        <p>{{record.message}}</p>
        <b-media>
          <template #aside>
            <b-avatar :text="avatarText(record.userEmail)" :variant="'light-'+resolveUserRoleVariant(record.userRole)" />
          </template>
          <h6>{{record.userName}} ({{record.userRole}})</h6>
          <p class="mb-0">{{record.userEmail}}</p>
        </b-media>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BAvatar, BMedia } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {avatarText} from '@core/utils/filter'
import capitalize from 'lodash.capitalize'
import { resolveUserRoleVariant, resolveProcessRecordVariant } from '@/common/Variants.js'

export default {
  components: {
    BAvatar,
    BMedia,
    BCard,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    records: {
      type: Array,
      required: true
    }
  },
  setup() {
    return { 
      resolveProcessRecordVariant,
      resolveUserRoleVariant,

      capitalize,
      avatarText
    }
  }
}
</script>
