<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center">
      <h2 class="title ff-arial fs-20 fw-600">What is the name of your business?</h2>
    </div>
    <form>
      <p>What name did you use to register the DBA?</p>

      <base-input name="business-name"
        label="CompanyName"
        opt-class="mb-2"
        v-model="companyName"
      />
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: String,
  },
  computed: {
    companyName: {
      get() {
        return store.state.forms.FormCompanyInfo.companyName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.companyName', value})
      }
    }
  }
}
</script>
