<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-1">
        <p>A corporation must have at least one director. A corporation with two stockholders must have at least two directors and a corporation with three or more stockholders must have at least three directors.</p>
      </div>

      <member-info-input
        v-for="id in countRange"
        :key="id"
        :id="id"
        @add="addDirector"
        @delete="deleteDirector"
      />
    </form>
  </div>
</template>

<script>
import range from 'lodash.range'
import store from '@/store'

export default {
  props: {
    title: String
  },
  methods: {
    deleteDirector(id) {
      store.commit(
        'forms/DELETE_ITEM_IN_FORM_ARRAY',
        {
          type: 'FormOrgMember',
          at: id
        }
      )
    },
    addDirector() {
      store.commit(
        'forms/ADD_NEW_ITEM_IN_FORM_ARRAY',
        {
          type: 'FormOrgMember'
        }
      )
    }
  },
  computed: {
    countRange() {
      return range(store.state.forms.FormOrgMember.length)
    }
  }
}
</script>