<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Business Information</h2>
    </div>
    <form>
      <div class="mb-1">
        <label for="number-of-partners" class="form-label">
          How many partners signatures will be required for filing company certificates or documents?
        </label>
        <select class="form-control" id="number-of-partners" v-model="numberOfPartners">
          <option value="">Select an option</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </div>

      <div class="mb-2">
        <label for="dateOfFormationMonth" class="form-label">What is the end of fiscal year?</label>
        <div class="d-flex">
          <select-month v-model="monthOfFiscalEnding" name="dateOfFormationMonth" opt-class="mr-2"/>
          <select-date v-model="dateOfFiscalEnding" />
        </div>
      </div>

    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Business Information'
    },
    showPrimaryActivity: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    /**
     * Fiscal Ending
     */
    monthOfFiscalEnding: {
      get() {
        return `${new Date(store.state.forms.FormCompanyName.endOfFiscalYear).getMonth()}`
      },
      set(value) {
        let fullDate = new Date(store.state.forms.FormCompanyName.endOfFiscalYear)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.endOfFiscalYear', value: fullDate})
      }
    },
    dateOfFiscalEnding: {
      get() {
        return `${new Date(store.state.forms.FormCompanyName.endOfFiscalYear).getDate()}`
      },
      set(value) {
        let fullDate = new Date(store.state.forms.FormCompanyName.endOfFiscalYear)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.endOfFiscalYear', value: fullDate})
      }
    },
    numberOfPartners: {
      get() {
        return store.state.forms.FormCompanyName.numberOfPartners
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.numberOfPartners', value})
      }
    }
  }
}
</script>
