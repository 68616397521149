<template>
  <div class="step-form mx-auto">
    <div>
      <div class="mb-2 text-center d-none d-block">
        <h2 class="title ff-arial fs-24 fw-600">Registered Agent</h2>
      </div>
      <div class="mb-2">
        <label><strong>Who will serve as the registered agent for your {{type}}?</strong></label>
        <p>Stay compliant with our managed annual report service. We will track your due dates, prepare your annual reports, and file on time each year. By law, all incorporated businesses must have a Registered Agent on file with the state.</p>
        <p>Registered Agent is someone that you designate to receive official documents for your business such as service of process, state correspondence, and other tax and legal documents.</p>
      </div>

      <div class="mb-2">
        <label><strong>The many benefits of using our Registered Agent service include</strong></label>
        <ul id="service-package-list" class="ml-4">
          <li>Keeping your information private</li>
          <li>Securely storing all important mail for anytime online access</li>
          <li>Mail forwarding of all legal documents directly to you</li>
          <li>Keeping your business compliant with state requirements</li>
          <li>Reducing junk mail</li>
        </ul>
      </div>
      <div class="mb-2">
        <p>By choosing StartABizzy.com to provide your Registered Agent service, there will be immediate charge of $125. As long as your business is in operation, it is required to have a Registered Agent on file with the Secretary of State. We offer our service on a recurring annual basis to make sure your business is always in compliance with the law.</p>
      </div>
      <form>
        <div class="mb-2">
          <label class="d-flex align-items-center mr-2" for="use-registered-agent">
            <input type="radio" name="registered-agent" id="use-registered-agent" class="mr-2" @click="handleCheck(true)" :checked="$store.state.forms.FormRegisterOption.needRegisteredAgent">
            <span>Yes! StartABizzy can provide our Registered Agent service.</span>
          </label>
          <label class="d-flex align-items-center mr-2" for="no-registered-agent">
            <input type="radio" name="registered-agent" id="no-registered-agent" class="mr-2" @click="handleCheck(false)" :checked="$store.state.forms.FormRegisterOption.needRegisteredAgent===false">
            <span>We will appoint a different Registered Agent.</span>
          </label>  
        </div>
      </form>
    </div>
    <div v-if="!needRegisteredAgent">
      <div class="mb-2 text-center">
        <h2 class="title ff-arial fs-24 fw-600">Appointed Registered Agent</h2>
      </div>

      <base-input
        name="agent-first-name"
        label="First Name *"
        v-model="agentFirstName"
        rules="required"
      />

      <base-input
        name="agent-last-name"
        label="Last Name *"
        v-model="agentLastName"
        rules="required"
      />

      <base-input
        name="agent-address"
        label="Address *"
        v-model="agentAddress"
        rules="required"
      />
      
      <base-input
        name="agent-address2"
        label="Apt, suite, unit, etc"
        v-model="agentAddress2"
      />

      <base-input
        name="agent-city"
        label="City *"
        v-model="agentCity"
        rules="required"
      />

      <select-state name="agent-state" v-model="agentState" rules="required" />

      <base-input
        name="agent-zip"
        label="ZIP *"
        type="number"
        opt-class="mb-4"
        v-model="agentZip"
        rules="required|numeric|min:5"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    type: {
      type: String,
      default: 'C-Corporation'
    }
  },
  methods: {
    handleCheck(value) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.needRegisteredAgent', value})
    }
  },
  computed: {
    needRegisteredAgent() {
      return store.state.forms.FormRegisterOption.needRegisteredAgent
    },

    agentFirstName: {
      get() {
        return store.state.forms.FormRegisterOption.agentFirstName
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.agentFirstName', value})
      }
    },
    agentLastName: {
      get() {
        return store.state.forms.FormRegisterOption.agentLastName
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.agentLastName', value})
      }
    },
    agentAddress: {
      get() {
        return store.state.forms.FormRegisterOption.agentAddress
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.agentAddress', value})
      }
    },
    agentAddress2: {
      get() {
        return store.state.forms.FormRegisterOption.agentAddress2
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.agentAddress2', value})
      }
    },
    agentCity: {
      get() {
        return store.state.forms.FormRegisterOption.agentCity
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.agentCity', value})
      }
    },
    agentState: {
      get() {
        return store.state.forms.FormRegisterOption.agentState
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.agentState', value})
      }
    },
    agentZip: {
      get() {
        return store.state.forms.FormRegisterOption.agentZip
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.agentZip', value})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
ul#service-package-list {
  list-style: disc;
}
</style>
