<template>
  <b-card>
    <h3 class="text-center">Summary</h3>
    <b-row class="form-group">
      <b-col cols="3">ID:</b-col>
      <b-col cols="9">{{getValueFromStore('_id')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Slug:</b-col>
      <b-col cols="9">{{getValueFromStore('slug')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Package:</b-col>
      <b-col cols="9">{{getValueFromStore('packageType')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Entity:</b-col>
      <b-col cols="9">{{getValueFromStore('entityType')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Applying State:</b-col>
      <b-col cols="9">{{getValueFromStore('applyingState')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Domestic?</b-col>
      <b-col cols="9">{{getValueFromStore('isDomestic')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Client:</b-col>
      <b-col cols="9">
        <b-link
          :to="{ name: 'user-detail', params: { id: getValueFromStore('client')._id }}"
          target="_blank"
        >
          {{getValueFromStore('client').name}} ({{getValueFromStore('client').email}})
        </b-link>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Assignee:</b-col>
      <b-col cols="9">
        <b-link
          v-if="!!getValueFromStore('assignee')._id"
          :to="{ name: 'user-detail', params: { id: getValueFromStore('assignee')._id }}"
          target="_blank"
        >
          {{getValueFromStore('assignee').name}} 
          ({{getValueFromStore('assignee').email}})
        </b-link>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Fee:</b-col>
      <b-col cols="9">${{getValueFromStore('amount')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Status:</b-col>
      <b-col cols="9">{{getValueFromStore('status')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">Payment Method:</b-col>
      <b-col cols="9">{{getValueFromStore('paymentMethod')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">CheckoutID:</b-col>
      <b-col cols="9">{{getValueFromStore('checkoutID')}}</b-col>
    </b-row>
    <b-row class="form-group">
      <b-col cols="3">CreatedAt:</b-col>
      <b-col cols="9">{{new Date(getValueFromStore('createdAt')).toLocaleString()}}</b-col>
    </b-row>
  </b-card>  
</template>

<script>
import { BCard, BCardBody, BCardText, BRow, BCol, BLink } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BLink
  },
  methods: {
    getValueFromStore(key) {
      return store.state.forms.submission[key]
    },
  },
}
</script>
