<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Business Licenses and Permits</h2>
    </div>
    <div class="mb-2">
      <p>Many businesses need to obtain at least one license (sometimes several) before they can legally begin their normal daily operations. Let us take the hassle out of researching and finding the licenses and permits you need to operate your business.</p>
    </div>

    <div class="mb-2">
      <label><strong>Business Licenses and Permits Package</strong></label>
      <p>The Business Licenses and Permits Package tells you exactly which state, federal, and local government licenses, permits, and tax registrations that are required for your business. Your customized Business License Compliance Package will include</p>
      <ul id="license-service-package-list" class="ml-4">
        <li>An overview of the licenses, permits, and tax registrations required for your business</li>
        <li>Licensing authority contact information, including name, address, telephone number, etc.</li>
        <li>The actual license, permit, and tax registration applications and associated documents</li>
      </ul>
    </div>
    <form>
      <div class="mb-2">
        <p><strong>Would you like to receive a Business Licenses and Permits Package for your business?</strong></p>
        <label class="d-flex align-items-center mr-3 mb-1" for="use-business-license">
          <input type="radio" name="business-license" id="use-business-license" class="mr-2" @click="handleCheck(true)" :checked="$store.state.forms.FormRegisterOption.needBizLicensesPermits">
          <span>Yes, I would like a Business Licenses and Permits Package ($99)</span>
        </label>
        <label class="d-flex align-items-center mr-3" for="no-business-license">
          <input type="radio" name="business-license" id="no-business-license" class="mr-2" @click="handleCheck(false)" :checked="$store.state.forms.FormRegisterOption.needBizLicensesPermits===false">
          <span> No, Thank you</span>
        </label>  
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  methods: {
    handleCheck(value) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.needBizLicensesPermits', value})
    }
  }
}
</script>

<style lang="scss" scoped>
ul#license-service-package-list {
  list-style: disc;
}
</style>
