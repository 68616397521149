export default ({$http}) => ({
  fetchUsers: (queryParams) => {
    return new Promise((resolve, reject) => {
      $http
        .get('/admin/users', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchUser: (id) => {
    return new Promise((resolve, reject) => {
      $http
        .get(`/admin/users/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  addUser: (userData) => {
    return new Promise((resolve, reject) => {
      $http
        .post('/admin/users', { user: userData })
        .then(response => resolve(response))
        .catch(error => {
          reject(error.response.data)
        })
    })
  }
})
