<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-3">
        <p>Each corporation must have a President, Treasurer and Secretary. Please note that one person may hold every office. If you have more than two shareholders, the President and Secretary should be different.</p>
      </div>
      <base-input
        name="president"
        label="President"
        placeholder="Full Name"
        v-model="president"
        required
      />
      
      <base-input
        name="treasurer"
        label="Treasurer"
        placeholder="Full Name"
        v-model="treasurer"
        required
      />

      <base-input
        name="secretary"
        label="Secretary"
        placeholder="Full Name"
        v-model="secretary"
        required
      />

      <base-input
        name="vice-president"
        label="Vice President (optional)"
        placeholder="Full Name"
        v-model="vicePresident"
      />
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: String,
  },
  computed: {
    president: {
      get() {
        return store.state.forms.FormCompanyOfficers.president
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.president', value})
      }
    },
    treasurer: {
      get() {
        return store.state.forms.FormCompanyOfficers.treasurer
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.treasurer', value})
      }
    },
    secretary: {
      get() {
        return store.state.forms.FormCompanyOfficers.secretary
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.secretary', value})
      }
    },
    vicePresident: {
      get() {
        return store.state.forms.FormCompanyOfficers.vicePresident
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.vicePresident', value})
      }
    }
  }
}
</script>
