<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-3">
        <slot>
          <p>What is the name of the company? The state requires the legal name of a company to be unique from other businesses who are registered within the same state. The business name you provide will be researched by one of our agents to ensure that it is available.</p>
        </slot>
      </div>

      <base-input
        name="preferred-company-name"
        label="Preferred Company Name"
        v-model="companyPreferredName"
        required
      />

      <base-input
        name="alt-company-name"
        label="Alternate Company Name"
        v-model="companyAltName"
        required
      />

      <base-input
        name="alt-company-name2"
        label="Alternate Company Name"
        v-model="companyAltName2"
        required
      />

      <select-designator
        v-if="selectDesignator"
        v-model="desigType"
        :entity-type="entityType"
      >
        Select Your Designator
      </select-designator>

      <select-state v-if="selectState" v-model="state">
        Business Location
      </select-state>

      <select-entity-type v-if="selectEntityType" v-model="entityType2">
        Select Your Business Entity Type
      </select-entity-type>
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Company Name'
    },
    entityType: {
      type: String,
    },
    selectDesignator: {
      type: Boolean,
      default: true
    },
    selectState: {
      type: Boolean,
      default: false
    },
    selectEntityType: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    companyPreferredName: {
      get() {
        return store.state.forms.FormCompanyName.preferredName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.preferredName', value})
      }
    },
    companyAltName: {
      get() {
        return store.state.forms.FormCompanyName.altName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.altName', value})
      }
    },
    companyAltName2: {
      get() {
        return store.state.forms.FormCompanyName.altName2
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.altName2', value})
      }
    },
    desigType: {
      get() {
        return this.$store.state.forms.FormCompanyName.designatorType
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.designatorType', value})
      }
    },
    state: {
      get() {
        return this.$store.state.forms.FormCompanyName.state
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.state', value})
      }
    },
    entityType2: {
      get() {
        return this.$store.state.forms.FormCompanyName.entityType
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.entityType', value})
      }
    },
  }
}
</script>

<style scoped>
#biz-purpose-example {
  font-size: 12px;
}
</style>
