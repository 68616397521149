<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Company Information</h2>
    </div>
    <form>
      <div class="mb-2">
        <p>Please enter the company name EXACTLY as it appears on your Articles. Also, select the entity type of the company, state of formation, and the date of your formation.</p>
      </div>

      <base-input
        name="company-name"
        label="Company Name"
        v-model="companyName"
        required
      />

      <div class="mb-2">
        <label for="dateOfFormationMonth" class="form-label">Date of Formation (approximate)</label>
        <div class="d-flex">
          <select-month v-model="dateOfFormationMonth" name="dateOfFormationMonth" opt-class="mr-2"/>
          <select-date v-model="dateOfFormationDate" opt-class="mr-2" />
          <select-year v-model="dateOfFormationYear" />
        </div>
      </div>

      <div class="mb-2">
        <label for="dateOfFormationMonth" class="form-label">Fiscal Ending</label>
        <div class="d-flex">
          <select-month v-model="monthOfFiscalEnding" name="dateOfFormationMonth" opt-class="mr-2"/>
          <select-date v-model="dateOfFiscalEnding" />
        </div>
      </div>

      <select-entity-type v-model="entityType" opt-class="mb-2" />
      <select-state v-model="state" opt-class="mb-2" />

      <select-yes-no-radio 
        name="is-foreign" 
        v-model="isForeign"
        opt-class="mb-2"
        v-if="selectForeignQuestion"
      >
        Is this a foreign entity?
      </select-yes-no-radio>
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    selectForeignQuestion: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    companyName: {
      get() {
        return store.state.forms.FormCompanyInfo.companyName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.companyName', value})
      }
    },
    isForeign: {
      get() {
        return store.state.forms.FormCompanyInfo.isForeign
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.isForeign', value})
      }
    },
    entityType: {
      get() {
        return store.state.forms.FormCompanyInfo.entityType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.entityType', value})
      }
    },
    state: {
      get() {
        return store.state.forms.FormCompanyInfo.state
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.state', value})
      }
    },

    dateOfFormationYear: {
      get() {
        return `${new Date(store.state.forms.FormCompanyInfo.dateOfFormation).getFullYear()}`
      },
      set(value) {
        let fullDate = new Date(store.state.forms.FormCompanyInfo.dateOfFormation)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.dateOfFormation', value: fullDate})
      }
    },
    dateOfFormationMonth: {
      get() {
        return `${new Date(store.state.forms.FormCompanyInfo.dateOfFormation).getMonth()}`
      },
      set(value) {
        let fullDate = new Date(store.state.forms.FormCompanyInfo.dateOfFormation)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.dateOfFormation', value: fullDate})
      }
    },
    dateOfFormationDate: {
      get() {
        return `${new Date(store.state.forms.FormCompanyInfo.dateOfFormation).getDate()}`
      },
      set(value) {
        let fullDate = new Date(store.state.forms.FormCompanyInfo.dateOfFormation)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.dateOfFormation', value: fullDate})
      }
    },
    /**
     * Fiscal Ending
     */
    monthOfFiscalEnding: {
      get() {
        return `${new Date(store.state.forms.FormCompanyInfo.fiscalEnding).getMonth()}`
      },
      set(value) {
        let fullDate = new Date(store.state.forms.FormCompanyInfo.fiscalEnding)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.fiscalEnding', value: fullDate})
      }
    },
    dateOfFiscalEnding: {
      get() {
        return `${new Date(store.state.forms.FormCompanyInfo.fiscalEnding).getDate()}`
      },
      set(value) {
        let fullDate = new Date(store.state.forms.FormCompanyInfo.fiscalEnding)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyInfo.fiscalEnding', value: fullDate})
      }
    }
  }
}
</script>
