<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Amending Your Articles</h2>
    </div>
    <form>
      <div class="mb-2">
        <p>Please list in detail all of the changes you would like made. Details you can amend - your company name, address, ownership, management, shares, etc.</p>
      </div>

      <div class="mb-2">
        <label for="amend-content" class="form-label mb-0">What are the changes you would like to make on file with the state?</label>
        <textarea class="form-control" id="amend-content" rows="5" v-model="amendContent"></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    amendContent: {
      get() {
        return store.state.forms.FormAmendInfo.amendContent
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormAmendInfo.amendContent', value})
      }
    },
  }
}
</script>
