<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Additional Information</h2>
    </div>
    <form>
      <select-yes-no-radio name="operateUnderTradeName" v-model="operateUnderTradeName" opt-class="mb-2">Will the Limited Partnership operate under a trade name?</select-yes-no-radio>
      <select-yes-no-radio name="isSubsidiaryOfAnother" v-model="isSubsidiaryOfAnother" opt-class="mb-2">Is this a subsidiary of another company?</select-yes-no-radio>

      <div class="mb-2">
        <label for="business-purpose" class="form-label mb-0">Business Purpose</label>
        <p class="mb-0" id="biz-purpose-example">Example: Frozen yogurt shop.</p>
        <textarea class="form-control" id="business-purpose" rows="5" v-model="companyBizPurpose"></textarea>
      </div>

      <div class="mb-2" v-if="showPrimaryActivity">
        <label for="primary-activity-description" class="form-label">If other, please describe your primary business activity</label>
        <textarea class="form-control" id="primary-activity-description" rows="5" v-model="companyPrimaryActivityDescription"></textarea>
      </div>
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Business Information'
    },
    showPrimaryActivity: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    operateUnderTradeName: {
      get() {
        return store.state.forms.FormCompanyName.operateUnderTradeName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.operateUnderTradeName', value})
      }
    },
    isSubsidiaryOfAnother: {
      get() {
        return store.state.forms.FormCompanyName.isSubsidiaryOfAnother
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.isSubsidiaryOfAnother', value})
      }
    },
    companyBizPurpose: {
      get() {
        return store.state.forms.FormCompanyName.bizPurpose
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.bizPurpose', value})
      }
    },
    companyPrimaryActivityDescription: {
      get() {
        return store.state.forms.FormCompanyName.primaryActivityDescription
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.primaryActivityDescription', value})
      }
    },   
  }
}
</script>

<style scoped>
#biz-purpose-example {
  font-size: 12px;
}
</style>
