import clone from 'lodash.clone'
import set from 'lodash.set'
import axios from '@axios'
import api from '@/api'

const FormTemplates = {
  FormCopyrightAuthor: {
    _id: null,
    name: '',
    mailingAddress: '',
    city: '',
    state: '',
    country: '',
    madeForHire: false,
    madeAnonymously: false,
    createdPseudonym: false,
    pseudonymName: '',

    contributeWrittenText: false,
    contributeArtwork: false,
    contributePhotographs: false,
    contributeComputerProgram: false,
    contributeTranslation: false,
    contributeCompilation: false,
    contributeOther: false,
    contributeOtherDetail: '',
  },
  FormOrgMember: {
    _id: null,
    memberType: 'director',
    memberName: '',
    memberAddress: '',
    memberAddress1: '',
    memberCity: '',
    memberState: '',
    memberZip: '',
    memberPhone: '',
    memberEmail: '',
  },
  FormTrademarkClass: {
    _id: null,
    productType: 'goods',
    productCategory: '',
    productDescription: ''
  },
}

const initialFormsData = {
  /* forms template */
  FormAmendInfo: {
    _id: null,
    amendContent: ''
  },
  FormBasicBizInfo: {
    _id: null,
    countryOfOrigin: '',
    stateOfOrigin: '',
    reasonForApplying: '',
    primaryActivity: '',
    specificProductsOrServices: '',
    hasMotorVehicle55KMore: null,
    involveGambling: null,
    sellAlcholTobaccoFirearm: null,
    payFedExciseTax: null,
    wishAnnualFileUnder4Kwages: null,
    appliedEinBefore: null,
    hireEmployeeIn12Months: null,
    previousFedTaxId: '',
    bizEmpTaxLiability1K: null,
    bizEmpTaxLiability1KFullDate: new Date(),
    numberOfAgriculEmployee: null,
    numberOfHouseholdEmployee: null,
    numberOfOtherEmployee: null,
  },
  FormBizBriefInfo: {
    _id: null,
    bizCategory: '',
    bizBriefDescription: ''
  },
  FormBizStateIdInfo: {
    _id: null,
    stateId: '',
  },
  FormBizAddress: {
    _id: null,
    address: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    hasDifferentMailingAddress: false,
    mailAddress: '',
    mailAddress1: '',
    mailCity: '',
    mailState: '',
    mailZip: ''
  },
  FormCertifiedCopy: {
    _id: null,
    documentType: ''
  },
  FormCompanyInfo: {
    _id: null,
    companyName: '',
    dateOfFormation: new Date(),
    /* default fiscal ending is: December 31 */
    fiscalEnding: new Date(2001, 11, 31),
    entityType: '',
    state: '',
    isForeign: null,
  },
  FormCompanyName: {
    _id: null,
    preferredName: '',
    altName: '',
    altName2: '',
    entityType: '',
    designatorType: '',
    bizCategory: '',
    bizPurpose: '',
    primaryActivityDescription: '',
    isSubsidiaryOfAnother: null,
    state: '',
    stateApplying: '',
    numberOfPartners: null,
    endOfFiscalYear: new Date(2001, 11, 31),
    operateUnderTradeName: null,
    typeOfTaxExemption: ''
  },
  FormCompanyOfficers: {
    _id: null,
    president: '',
    treasurer: '',
    secretary: '',
    vicePresident: ''
  },
  FormContactInfo: {
    _id: null,
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    title: ''
  },
  FormConvertInfo: {
    _id: null,
    convertType: '',
    convertDescription: ''
  },
  FormCopyrightInfo: {
    _id: null,
    workType: '',
    workTitle: '',
    completeYear: null,

    hasPublished: false,
    publishDate: new Date(2020, 1, 1),
    publishedCountry: '',
    hasEPublishedOnly: false,

    IsPartOfLargerPub: false,
    titleOfLargerPub: '',
    dateOfLargerPub: new Date(2020, 1, 1),
    volumeNumberOfLargerPub: '',

    haveMaterialPreexisted: false,
    existingWrittenText: false,
    existingArtwork: false,
    existingPhotographs: false,
    existingComputerProgram: false,
    existingTranslation: false,
    existingCompilation: false,
    existingOther: false,
    existingOtherDetail: '',

    registerAsOthers: false,
    transferType: '',
    registerName: '',
    registerAddress: '',
    registerPhone: '',
    registerEmail: '',

    packageType: '',
    usptoFiling: true,
  },
  FormCopyrightAuthor: [],
  FormDateBizAcquired: {
    _id: null,
    bizAcquiredDate: new Date(),
    accountingEndMonth: null
  },
  FormDissolveInfo: {
    _id: null,
    dissolvingReason: '', 
    dissolvingCompanyEmployerId: '',
    dissolvingCompanyStateID: ''
  },
  FormIndividualInfo: {
    _id: null,
    firstName: '', 
    lastName: '',
    ssn: '',
  },
  FormNonprofitInfo: {
    _id: null,
    companyName: '',
    state: '',
    title: '',
    statesRegistered: null,
    description: '',
    country: ''
  },
  FormOrgInfo: {
    _id: null,
    organizationName: '',
    tradeNameOrDBA: '',
    ownedByAnother: null,
    organizationType: '',
    numberOfMembers: null,
  },
  FormOrgMember: [],
  FormOrgMemberType: 'member',
  FormPersonalInfo: {
    _id: null,
    firstName: '',
    lastName: '',
    ssn: '',
    title: '',
  }, 
  FormReceiptInfo: {
    _id: null,
    name: '',
    email: '',
    tel: ''
  },
  FormRegisterOption: {
    _id: null,
    needRegisteredAgent: null,
    needBizLicensesPermits: null,
    needSellersPermit: null,

    agentFirstName: '',
    agentLastName: '',
    agentAddress: '',
    agentAddress2: '',
    agentCity: '',
    agentState: '',
    agentZip: ''
  },
  FormStockInfo: {
    _id: null,
    stockCount: 0,
    stockPrice: 0
  },
  FormTaxInfo: {
    _id: null,
    needEinTaxID: null,
    bizSSN: '',
    hasMotorVehicle55KMore: null,
    involveGambling: null,
    sellAlcholTobaccoFirearm: null,
    payFedExciseTax: null,
    wishAnnualFileUnder4Kwages: null,
    appliedEinBefore: null,
    hireEmployeeIn12Months: null,
    previousFedTaxId: '',
    bizEmpTaxLiability1K: null,
    bizEmpTaxLiability1KFullDate: new Date(),
    numberOfAgriculEmployee: null,
    numberOfHouseholdEmployee: null,
    numberOfOtherEmployee: null,
  },
  FormTrademarkInfo: {
    _id: null,
    markType: '',
    name: '',
    slogan: '',
    logoDescription: '',
    logoAllColor: true,
    logoColors: '',
    logoWords: '',
    includeLivePersonName: false,
    includeYourName: false,
    yourLegalName: '',
    includeOthersName: false,
    othersName: '',

    includeNonLatin: false,
    translateContent: '',
    usingThisAlready: false,
    firstUseDate: new Date(),
    firstUseInEcommerceDate: new Date(),

    ownedByIndividual: true,
    organizationName: '',
    organizationType: '',
    orgStateOfFormation: '',
    orgRepresentativePosition: '',

    searchReportType: '',
    packageType: '',
    expeditedProcessing: false,
    usptoFiling: true,
  },
  FormTrademarkClass: [],
  FormWithdrawalInfo: {
    _id: null,
    state: '',
    withdrawalReason: ''
  },
  FormWebDevelopmentInfo: {
    packageType: '',
    bizName: '',
    bizDescription: '',
    buildNew: true,

    currentURL: '',
    hostingProvider: '',
    domainRegistrar: '',

    favoriteSites: '',
    portrayOfYourSite: '',
    appreciateDislikeFeature: '',
    updateFrequency: 'monthly',
    contentProvider: 'self',
    deadlineOfWebsite: new Date(),

    haveGuidelineOfLogoAndImage: false,
    guidelineOfLogoAndImage: '',
    underlyingGoalOfWebsite: ''
  },
  /**
   * Checkout, Shipping Address
   */
   FormShippingAddress: {
    _id: null,
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    state: '',
    zip: ''
  },
  /* submission detail */
  submission: {},
  attachments: [],
  isReadOnly: true,
}

export default {
  namespaced: true,
  state: initialFormsData,
  getters: {},
  mutations: {
    /**
     * Form Manipulation
     */
    RESET_ALL_FORMS_INFO(state) {
      Object.keys(initialFormsData).forEach(key => {
        state[key] = clone(initialFormsData[key])
      })
    },
    SET_FORM_INFO(state, payload) {
      if (Object.keys(FormTemplates).includes(payload.type)) {
        state[payload.type].push(payload.data)  
      } else {
        Object.assign(state[payload.type], payload.data)
      }
    },
    SET_FORM_ATTRIBUTE(state, payload) {
      if (!state.isReadOnly) set(state, payload.key, payload.value)
    },
    ADD_NEW_ITEM_IN_FORM_ARRAY(state, payload) {
      state[payload.type].push({...FormTemplates[payload.type], ...payload.additional})
    },
    DELETE_ITEM_IN_FORM_ARRAY(state, payload) {
      state[payload.type].splice(payload.at, 1)
    },
    CHANGE_ORG_ALL_MEMBER_TYPE(state, payload='member') {
      if (!state.isReadOnly) {
        state.FormOrgMemberType = payload
        state.FormOrgMember.forEach((member) => {
          member.memberType = payload
        })
      }
    },

    /**
     * Submission
     */
    SET_SUBMISSION(state, payload) {
      state.submission = payload
    },
    INVALID_SUBMISSION(state) {
      state.submission = {
        slug: 'not-found'
      }
    },

    /**
     * Records
     */
    ADD_PROCESS_RECORD(state, payload) {
      state.submission.processRecords.unshift(payload)
    },

    /**
     * Attachments
     */
    ADD_ATTACHMENT_INFO(state, payload) {
      state.submission.attachments.unshift(payload)
    },
    SET_ATTACHMENT_INFO(state, {id, data}) {
      state.submission.attachments[id] = data
    },
    RESET_ATTACHMENT_INFO(state) {
      state.submission.attachments = []
    }
  },
  actions: {
    fetchSubmission(ctx, id) {
      ctx.commit('RESET_ALL_FORMS_INFO')
      api.submissions.fetchSubmission(id)
        .then(data => {
          const {forms, ...submission} = data
          ctx.commit('SET_SUBMISSION', submission)
          forms.forEach(form => {
            ctx.commit('SET_FORM_INFO', {type: form.type, data: form})
          })
        })
        .catch(error => {
          ctx.commit('INVALID_SUBMISSION')
        })
    },
    submitProcessRecord(ctx, payload) {
      api.submissions.addProcessRecord(ctx.state.submission._id, payload)
        .then(result => {
          ctx.commit('ADD_PROCESS_RECORD', result)
        })
        .catch()
    },
    attachFile(ctx, payload) {
      api.submissions.attachFile(ctx.state.submission._id, payload)
        .then((data) => {
          ctx.commit('ADD_ATTACHMENT_INFO', data.result)
          ctx.commit('ADD_PROCESS_RECORD', data.record)
        })
        .catch()
    }
  }
}
