<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Converting Your Company</h2>
    </div>
    <form>
      <div class="mb-2">
        <p>Please enter the type of conversion you would like to perform along with some other basic business information.</p>
      </div>

      <div class="mb-2">
        <label for="convert-type" class="form-label">Select Your Type of Conversion</label>
        <select class="form-control" id="convert-type" name="convert-type" v-model="convertType">
          <option value="">select an option</option>
          <option value="LLC To Corporation">LLC To Corporation</option>
          <option value="Corporation to LLC">Corporation to LLC</option>
        </select>
      </div>

      <div class="mb-2">
        <label for="convert-description" class="form-label mb-0">Brief Business Description</label>
        <p class="mb-0" id="convert-description">Example: Operate a Jet ski rental service.</p>
        <textarea class="form-control" id="convert-description" rows="5" v-model="convertDescription"></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    convertType: {
      get() {
        return store.state.forms.FormConvertInfo.convertType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormConvertInfo.convertType', value})
      }
    },
    convertDescription: {
      get() {
        return store.state.forms.FormConvertInfo.convertDescription
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormConvertInfo.convertDescription', value})
      }
    },
  }
}
</script>
