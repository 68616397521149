<template>
  <div :class="optClass">
    <select class="form-control" :id="name" @change="change" :value="value">
      <option value="">Select an option</option>
      <option value="0">January</option>
      <option value="1">February</option>
      <option value="2">March</option>
      <option value="3">April</option>
      <option value="4">May</option>
      <option value="5">June</option>
      <option value="6">July</option>
      <option value="7">August</option>
      <option value="8">September</option>
      <option value="9">October</option>
      <option value="10">November</option>
      <option value="11">December</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'month'
    },
    optClass: {
      type: String,
      default: 'mb-1'
    },
    value: String | Number
  }
}
</script>