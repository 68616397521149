<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-3">
        <slot>
          <p>What would you like to name your business? Please list the name exactly how you plan to use or advertise it (with all spaces, punctuation marks, etc.). The business name you provide will be researched by one of our staff member.</p>
        </slot>
      </div>

      <base-input
        name="preferred-company-name"
        label="Preferred Company Name"
        v-model="companyPreferredName"
        required
      />

      <base-input
        name="alt-company-name"
        label="Alternate Company Name"
        v-model="companyAltName"
        required
      />

      <select-primary-activity v-model="companyBizCategory">
        Choose Your Business Category
      </select-primary-activity>

      <div class="mb-2">
        <label for="primary-activity-description" class="form-label">Please describe your business activity</label>
        <textarea class="form-control" id="primary-activity-description" rows="5" v-model="companyPrimaryActivityDescription"></textarea>
      </div>
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String
    },
  },
  computed: {
    companyPreferredName: {
      get() {
        return store.state.forms.FormCompanyName.preferredName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.preferredName', value})
      }
    },
    companyAltName: {
      get() {
        return store.state.forms.FormCompanyName.altName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.altName', value})
      }
    },
    companyBizCategory: {
      get() {
        return store.state.forms.FormCompanyName.bizCategory
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.bizCategory', value})
      }
    },
    companyPrimaryActivityDescription: {
      get() {
        return store.state.forms.FormCompanyName.primaryActivityDescription
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.primaryActivityDescription', value})
      }
    },
  }
}
</script>

<style scoped>
#biz-purpose-example {
  font-size: 12px;
}
</style>
