<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600"><slot>Information on the Deceased Individual</slot></h2>
    </div>
    <form>
      <base-input
        name="first-name"
        label="First name"
        v-model="individualFirstName"
        required
      />
      
      <base-input
        name="last-name"
        label="Last name"
        v-model="individualLastName"
        required
      />

      <base-input
        name="ssn"
        label="Social Security Number🔒"
        placeholder="[XXX-XX-XXX]"
        maxlength="9"
        v-model="individualSSN1"
        required
      />
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    showBackNav: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    individualFirstName: {
      get() {
        return store.state.forms.FormIndividualInfo.firstName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormIndividualInfo.firstName', value})
      }
    },
    individualLastName: {
      get() {
        return store.state.forms.FormIndividualInfo.lastName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormIndividualInfo.lastName', value})
      }
    },
    individualSSN1: {
      get() {
        return store.state.forms.FormIndividualInfo.ssn
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormIndividualInfo.ssn', value})
      }
    }
  }
}
</script>
