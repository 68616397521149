<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Dissolving Company</h2>
    </div>
    <form>
      <div class="mb-2">
        <p>Please list your Employer ID Number (EIN), State ID (File) Number, and your reason for dissolving your business.</p>
      </div>

      <div class="mb-2">
        <label for="reason" class="form-label">Reason for dissolving?</label>
        <textarea class="form-control" id="reason" rows="5" v-model="dissolvingReason"></textarea>
      </div>
      
      <base-input
        name="ein"
        label="Employer ID Number (EIN) (optional)"
        v-model="dissolvingCompanyEmployerId"
        required
      />

      <base-input
        name="state-id"
        opt-class="mb-2"
        label="State ID (File) Number (optional)"
        v-model="dissolvingCompanyStateID"
      />
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    dissolvingReason: {
      get() {
        return store.state.forms.FormDissolveInfo.dissolvingReason
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormDissolveInfo.dissolvingReason', value})
      }
    },
    dissolvingCompanyEmployerId: {
      get() {
        return store.state.forms.FormDissolveInfo.dissolvingCompanyEmployerId
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormDissolveInfo.dissolvingCompanyEmployerId', value})
      }
    },
    dissolvingCompanyStateID: {
      get() {
        return store.state.forms.FormDissolveInfo.dissolvingCompanyStateID
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormDissolveInfo.dissolvingCompanyStateID', value})
      }
    }
  }
}
</script>
