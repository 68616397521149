<template>
  <div>
    <div class="mb-1 border-bottom pb-0 d-flex justify-content-start">
      <p><strong>{{type}} #{{id+1}}</strong></p>
    </div>
    <div class="mb-1">
        <label :for="'name-'+id" class="form-label">Name</label>
        <input 
          type="text" 
          :id="'name-'+id"
          name="name" 
          class="form-control" 
          :value="$store.state.forms.FormOrgMember[id].memberName"
          @input="handleNameChange"
          required>
      </div>

      <div class="mb-1">
        <label :for="'address-'+id" class="form-label">Address</label>
        <input
          type="text" 
          :id="'address-'+id"
          name="address"
          class="form-control"
          :value="$store.state.forms.FormOrgMember[id].memberAddress"
          @input="handleAddressChange"
          required
        >
      </div>
      
      <div class="mb-1">
        <label :for="'address1-'+id" class="form-label">Apt, suite, unit, etc</label>
        <input 
          type="text" 
          :id="'address1-'+id"
          name="address1" 
          class="form-control" 
          :value="$store.state.forms.FormOrgMember[id].memberAddress1"
          @input="handleAddress1Change"
          required 
        >
      </div>

      <div class="mb-1">
        <label :for="'city-'+id" class="form-label">City</label>
        <input 
          type="text" 
          :id="'city-'+id" 
          name="city" 
          class="form-control" 
          :value="$store.state.forms.FormOrgMember[id].memberCity"
          @input="handleCityChange"
          required
        >
      </div>

      <select-state 
        name="state"
        :value="$store.state.forms.FormOrgMember[id].memberState"
        @input="handleStateChange"
      />

      <div class="mb-2">
        <label :for="'zip-'+id" class="form-label">ZIP</label>
        <input 
          type="number" 
          :id="'zip-'+id" 
          name="zip" 
          class="form-control" 
          :value="$store.state.forms.FormOrgMember[id].memberZip"
          @input="handleZipChange"
          required
        >
      </div>

      <div class="mb-2" v-if="selectPartnerType">
        <p><strong>Is this person a General Partner or a Limited Partner?</strong></p>
        <label class="d-flex align-items-center mr-3 mb-0" :for="'general-partner-'+id">
          <input type="radio" :name="'partner-type-'+id" :id="'general-partner-'+id" class="mr-2" @click="handleType('general-partner')" :checked="$store.state.forms.FormOrgMember[id].memberType==='general-partner'">
          <span>General Partner</span>
        </label>
        <label class="d-flex align-items-center mr-3" :for="'limited-partner-'+id">
          <input type="radio" :name="'partner-type-'+id" :id="'limited-partner-'+id" class="mr-2" @click="handleType('limited-partner')" :checked="$store.state.forms.FormOrgMember[id].memberType==='limited-partner'">
          <span>Limited Partner</span>
        </label>  
      </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'Director'
    },
    selectPartnerType: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleDelete() {
      console.log(store.state.forms.FormOrgMember.length)
      if (confirm(`Are you sure to delete this ${this.type.toLowerCase()}?`)) {
        this.$emit('delete', this.id)
      }
    },
    handleAdd() {
      this.$emit('add')
    },
    handleNameChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberName`, value: e.target.value})
    },
    handleAddressChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberAddress`, value: e.target.value})
    },
    handleAddress1Change(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberAddress1`, value: e.target.value})
    },
    handleCityChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberCity`, value: e.target.value})
    },
    handleStateChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberState`, value: e})
    },
    handleZipChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberZip`, value: e.target.value})
    },
    handleType(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormOrgMember[${this.id}].memberType`, value: e})
    },
  }
}
</script>
