<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-2">
        <slot>
          <p>Please provide a brief description about your business in order to better help us provide the most in-depth research.</p>
        </slot>
      </div>

      <select-primary-activity v-model="bizCategory">
        Choose Your Business Category
      </select-primary-activity>

      <div class="mb-2">
        <label for="convert-description" class="form-label mb-0">Brief Business Description</label>
        <p class="mb-0" id="convert-description">Example: Operate a Jet ski rental service.</p>
        <textarea class="form-control" id="convert-description" rows="5" v-model="bizBriefDescription"></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Business Licenses and Permits'
    }
  },
  computed: {
    bizCategory: {
      get() {
        return store.state.forms.FormBizBriefInfo.bizCategory
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizBriefInfo.bizCategory', value})
      }
    },
    bizBriefDescription: {
      get() {
        return store.state.forms.FormBizBriefInfo.bizBriefDescription
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizBriefInfo.bizBriefDescription', value})
      }
    },
  }
}
</script>
