<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-3" v-if="showDescription">
        <p>If you are starting a new business, enter the starting date of the business. If the business you acquired is already operating, enter the date you acquired the business.</p>
      </div>

      <select-month v-model="bizAcquiredMonth" />
      <select-date v-model="bizAcquiredDate" />
      <select-year opt-class="mb-3" v-model="bizAcquiredYear" />

      <div>
        <strong>Accounting Year End</strong>
      </div>

      <div>
        <p>When will your accounting period end?</p>
      </div>

      <select-month  opt-class="mb-5" v-model="accountingEndMonth" />
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Date Business Acquired'
    },
    showDescription: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    accountingEndMonth: {
      get() {
        return store.state.forms.FormDateBizAcquired.accountingEndMonth
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormDateBizAcquired.accountingEndMonth', value})
      }
    },
    bizAcquiredYear: {
      get() {
        return `${new Date(store.state.forms.FormDateBizAcquired.bizAcquiredDate).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormDateBizAcquired.bizAcquiredDate)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormDateBizAcquired.bizAcquiredDate', value: fullDate})
      }
    },
    bizAcquiredMonth: {
      get() {
        return `${new Date(store.state.forms.FormDateBizAcquired.bizAcquiredDate).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormDateBizAcquired.bizAcquiredDate)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormDateBizAcquired.bizAcquiredDate', value: fullDate})
      }
    },
    bizAcquiredDate: {
      get() {
        return `${new Date(store.state.forms.FormDateBizAcquired.bizAcquiredDate).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormDateBizAcquired.bizAcquiredDate)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormDateBizAcquired.bizAcquiredDate', value: fullDate})
      }
    }
  }
}
</script>
