import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import jwt from '@/auth/jwt/useJwt'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: {
        name: 'submission-list',
      },
    },
    {
      path: '/submissions',
      name: 'submission-list',
      component: () => import('@/views/submission/SubmissionIndex.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'Submission List',
        breadcrumb: [
          {
            text: 'Submissions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/submissions/:id',
      name: 'submission-detail',
      component: () => import('@/views/submission/SubmissionDetail.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'Submission Detail',
        breadcrumb: [
          {
            text: 'Submissions',
            active: false,
            to: '/submissions',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/renewals',
      name: 'renewal-list',
      redirect: 'notfound',
    },
    {
      path: '/renewals/:id',
      name: 'renewal-detail',
      redirect: 'notfound',
    },
    {
      path: '/transaction-list',
      name: 'transaction-list',
      redirect: 'notfound',
    },
    {
      path: '/users',
      name: 'users-list',
      component: () => import('@/views/user/UserIndex.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'User List',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:id',
      name: 'user-detail',
      component: () => import('@/views/user/detail/UserDetail.vue'),
      meta: {
        authRequired: true,
        pageTitle: 'User Detail',
        breadcrumb: [
          {
            text: 'Users',
            active: false,
            to: '/users'
          },
          {
            text: 'Detail',
            active: true
          }
        ],
      }
    },
    {
      path: '/profile',
      name: 'apps-users-edit',
      redirect: 'notfound',
    },
    {
      path: '/to-do',
      name: 'apps-todo',
      redirect: 'notfound',
    },
    {
      path: '/chat',
      name: 'apps-chat',
      redirect: 'notfound',
    },
    {
      path: '/email',
      name: 'apps-email',
      redirect: 'notfound',
    },
    {
      path: '/calendar',
      name: 'apps-calendar',
      redirect: 'notfound',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'notfound',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = store.state.auth.loggedIn
  if (to.meta.authRequired && !isLoggedIn) {
    return next({ name: 'login' })
  }

  if (to.meta.authRequired && isLoggedIn && !jwt.getToken()) {
    // token expired
    store.dispatch('auth/logout')
    return next({ name: 'login' })
  }

  return next()
})
export default router
