<template>
  <div :class="optClass">
    <label :for="name" class="form-label"><slot>Reason for Applying</slot></label>
    <select class="form-control" :id="name" @change="change" :value="value">
      <option value="">Select an option</option>
      <option value="Started New Business">Started New Business</option>
      <option value="Hired Employees">Hired Employees</option>
      <option value="IRS Compliance">IRS Compliance</option>
      <option value="Created Trust">Created Trust</option>
      <option value="Banking Purposes">Banking Purposes</option>
      <option value="Changed Type Of Organization">Changed Type Of Organization</option>
      <option value="Purchased A Business">Purchased A Business</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'reasonOfApplying'
    },
    optClass: {
      type: String,
      default: 'mb-1'
    },
    value: String
  }
}
</script>