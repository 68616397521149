<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Seller's Permit (Sales Tax ID)</h2>
    </div>
    <div class="mb-2">
      <p>The purpose of a Seller's Permit is to allow your business to sell tangible goods in your state of operation. You must collect sales tax on any physical product you sell through your business. For example, if you sell toys, food, t-shirts, or any other physical item, you must collect state sales tax.</p>
    </div>

    <form>
      <div class="mb-2">
        <p class="mb-1"><strong>Would you like us to complete your Seller's Permit application?</strong></p>
        <label class="d-flex align-items-center mb-1">
          <input type="radio" name="sellers-permit" id="use-sellers-permit" class="mr-2" @click="handleCheck(true)" :checked="$store.state.forms.FormRegisterOption.needSellersPermit">
          <span>Yes,  I want StartABizzy.com to obtain my Seller's Permit ($129)</span>
        </label>
        <label class="d-flex align-items-center">
          <input type="radio" name="sellers-permit" id="no-sellers-permit" class="mr-2" @click="handleCheck(false)" :checked="$store.state.forms.FormRegisterOption.needSellersPermit===false">
          <span> No, Thank you</span>
        </label>  
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  methods: {
    handleCheck(value) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.needSellersPermit', value})
    }
  }
}
</script>
