<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <base-input
        name="first-name"
        label="First name"
        v-model="personalFirstName"
        required
      />
      
      <base-input
        name="last-name"
        label="Last name"
        v-model="personalLastName"
        required
      />

      <base-input
        v-if="requireSsn"
        name="ssn"
        label="Social Security Number🔒"
        placeholder="[XXX-XX-XXX]"
        maxlength="9"
        v-model="personalSSN1"
        required
      />

      <base-input
        name="title"
        opt-class="mb-4"
        v-model="personalTitle"
        v-if="memberOfOrganization"
        required
      >
        <template v-slot:label>Title <em v-if="requireSsn">[eg. Owner/Managing Member]</em></template>
      </base-input>

      <div class="mb-4" v-if="!memberOfOrganization">
        <label for="title" class="form-label">Title of the Responsible Party</label>
        <select class="form-control" id="title" name="title" v-model="personalTitle">
          <option value="">select an option</option>
          <option value="Executor">Executor</option>
          <option value="Administrator">Administrator</option>
          <option value="Personal Representative">Personal Representative</option>
        </select>
      </div>
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: String,
    requireSsn: {
      type: Boolean,
      default: true
    },
    memberOfOrganization: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
    personalFirstName: {
      get() {
        return store.state.forms.FormPersonalInfo.firstName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormPersonalInfo.firstName', value})
      }
    },
    personalLastName: {
      get() {
        return store.state.forms.FormPersonalInfo.lastName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormPersonalInfo.lastName', value})
      }
    },
    personalSSN1: {
      get() {
        return store.state.forms.FormPersonalInfo.ssn
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormPersonalInfo.ssn', value})
      }
    },
    personalTitle: {
      get() {
        return store.state.forms.FormPersonalInfo.title
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormPersonalInfo.title', value})
      }
    },
  }
}
</script>
