<template>
  <div class="step-form">
    <div class="mb-2 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Trademark Registration</h2>
    </div>

    <div class="mb-1">
      <p class="mb-1">Why get a trademark?  A registered mark gives you a presumption of ownership and a presumed right to use the brand nationwide giving you broader protection in courts. Once registered, present yourself as an established and serious business with the ® symbol after your name, logo or slogan.</p>
      <p>Own the exclusive rights to your brand names, slogans, and logos. Once you give us the green light, we’ll e-file with the United States Patent and Trademark Office (USPTO)—and you’ll get a decision in typically three to six months.</p>
    </div>

    <form>
      <div class="mb-1">
        <p class="mb-0"><strong>Please select your Trademark Registration package.</strong></p>
        <label class="d-flex align-items-center mb-0">
          <input type="radio" name="trademark-plan" id="trademark-plan-basic" class="mr-2" @click="handleSelect('basic')" :checked="$store.state.forms.FormTrademarkInfo.packageType==='basic'">
          <span>Basic : <strong>$99</strong> (+ USPTO filing fee)</span>
        </label>
        <label class="d-flex align-items-center mb-0">
          <input type="radio" name="trademark-plan" id="trademark-plan-standard" class="mr-2" @click="handleSelect('standard')" :checked="$store.state.forms.FormTrademarkInfo.packageType==='standard'">
          <span>Standard : <strong>$149</strong> (+ USPTO filing fee)</span>
        </label>
        <label class="d-flex align-items-center mb-0">
          <input type="radio" name="trademark-plan" id="trademark-plan-deluxe" class="mr-2" @click="handleSelect('deluxe')" :checked="$store.state.forms.FormTrademarkInfo.packageType==='deluxe'">
          <span>Deluxe : <strong>$199</strong> (+ USPTO filing fee)</span>
        </label>
        <label class="d-flex align-items-center">
          <input type="radio" name="trademark-plan" id="trademark-plan-no" class="mr-2" @click="handleSelect('')" :checked="$store.state.forms.FormTrademarkInfo.packageType===''">
          <span>No, Thank you</span>
        </label>  
      </div>

      <select-radio name="mark-type" opt-class="mb-2"
        :items="[{name: 'Name', value: 'name'}, {name: 'Slogan', value: 'slogan'}, {name: 'Logo', value: 'logo'}]"
        v-model="markType"
        v-show="$store.state.forms.FormTrademarkInfo.packageType"
      >
        Select what you are trying to protect
      </select-radio>
    </form>

    <div class="mb-1" v-show="$store.state.forms.FormTrademarkInfo.packageType">
      <label><strong>What type of trademark search would you like us to conduct? *</strong></label>
      <p class="mb-1">The search you choose will be conducted on the words or text within your trademark.</p>
      <!-- radio options for trademark, slogan -->
      <form v-if="$store.state.forms.FormTrademarkInfo.markType!=='logo'">
        <label class="d-flex align-items-start mb-0">
          <input type="radio" name="search-report-option" id="basic-federal-direct-hit-search" class="mt-1 mr-2" value="trademark-basic-federal-direct-hit-search" v-model="serachType">
          <p class="mb-0">
            <span><strong>Basic Federal Direct-Hit Search (Included - Free)</strong></span><br/>
            <span class="text-example">Includes a basic search of USPTO database only</span>
          </p>
        </label>

        <label class="d-flex align-items-start mb-0">
          <input type="radio" name="search-report-option" id="federal-state-search" class="mt-1 mr-2" value="trademark-federal-state-search" v-model="serachType">
          <p class="mb-0">
            <span><strong>Federal &amp; State Search (+$99)</strong></span> <span class="badge badge-primary">Most Popular</span><br/>
            <span class="text-example">Provides any similar names, logos, or slogans that are registered or pending with the USPTO or in any of the 50 states</span>
          </p>
        </label>
        <label class="d-flex align-items-start mb-0">
          <input type="radio" name="search-report-option" id="federal-state-common-law-search" class="mt-1 mr-2" value="trademark-federal-state-common-law-search" v-model="serachType">
          <p class="mb-0">
            <span><strong>Federal, State &amp; Common Law Search (+$149)</strong></span><span class="badge badge-success">Recommended</span><br/>
            <span class="text-example">Same as above, but also includes a proprietary search of corporate directories, common law and domain names.</span>
          </p>
        </label>
        <label class="d-flex align-items-start mb-0">
          <input type="radio" name="search-report-option" id="global-comprehensive-search" class="mt-1 mr-2" value="trademark-global-comprehensive-search" v-model="serachType">
          <p class="mb-0">
            <span><strong>Global Comprehensive U.S. and International Search (+$229)</strong></span><br/>
            <span class="text-example">Same as above, but also includes searches for pending and registered marks in these multi-national jurisdictions: (i) The European community; and (ii) the World Intellectual Property Organization (WIPO)</span>
          </p>
        </label>
      </form>

      <!-- radio options for logo -->
      <form v-if="$store.state.forms.FormTrademarkInfo.markType==='logo'">
        <label class="d-flex align-items-start mb-0">
          <input type="radio" name="search-report-option" id="trademark-comprehensive-federal-logo-search" class="mt-1 mr-2" value="trademark-comprehensive-federal-logo-search" v-model="serachType">
          <p class="mb-0">
            <span><strong>Comprehensive Federal Logo Search (+$99)</strong></span><br/>
            <span class="text-example">Includes a comprehensive search of USPTO database</span>
          </p>
        </label>
        <label class="d-flex align-items-start mb-0">
          <input type="radio" name="search-report-option" id="no-logo-search" class="mt-1 mr-2" value="no-logo-search" v-model="serachType">
          <p class="mb-0">
            <span><strong>No Thanks</strong></span><br/>
            <span class="text-example">We will file your application performing only a basic search</span>
          </p>
        </label>
      </form>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  methods: {
    handleSelect(value) {
      store.commit('SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.packageType', value})
    }
  },
  computed: {
    markType: {
      get() {
        return store.state.forms.FormTrademarkInfo.markType
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.markType', value})
        if (value === 'logo') {
          store.commit('SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.searchReportType', value: 'no-logo-search'})
        } else {
          store.commit('SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.searchReportType', value: 'trademark-basic-federal-direct-hit-search'})
        }
      }
    },
    serachType: {
      get() {
        return store.state.forms.FormTrademarkInfo.searchReportType
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormTrademarkInfo.searchReportType', value})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
ul#webservice-package-list {
  list-style: disc;
}
</style>
