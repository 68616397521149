<template>
  <div :class="optClass">
    <label :for="name" class="form-label">
      <slot>
        Which type of tax exemption do you plan on applying for with the IRS?
      </slot>
    </label>
    <select class="form-control" :id="name" @change="change" :value="value">
      <option value="">Select an option</option>
      <option value="501(c)(3)">501(c)(3)-(most common) Various charitable, non-profit,religious,and educational organization</option>
      <option value="no-tax-exemption">No Tax Exemption</option>
      <option value="i-don't-know">I don't know, need help choosing </option>
      <option value="501(c)(1)">501(c)(1)-Corporations Organized Under Act of Congress (including Federal Credit Unions)</option>
      <option value="501(c)(2)">501(c)(2)-Title Holding Corporations for Exempt Organization</option>
      <option value="501(c)(4)">501(c)(4)-Civic Leagues and Social Welfare Organizations and Local Associations of Employees</option>
      <option value="501(c)(5)">501(c)(5)-Labor, Agricultural and Horticultural Organizations</option>
      <option value="501(c)(6)">501(c)(6)-Business Leagues, etc.</option>
      <option value="501(c)(7)">501(c)(7)-Social and Recreation Clubs</option>
      <option value="501(c)(8)">501(c)(8)-Fraternal Beneficiary Societies</option>
      <option value="501(c)(9)">501(c)(9)-Voluntary Employees' Beneficiary Associations</option>
      <option value="501(c)(10)">501(c)(10)-Domestic Fraternal Societies</option>
      <option value="501(c)(11)">501(c)(11)-Teachers' Retirement Fund Associations</option>
      <option value="501(c)(12)">501(c)(12)-Local Benevolent Life Insurance Associations</option>
      <option value="501(c)(13)">501(c)(13)-Cemetery Companies </option>
      <option value="501(c)(14)">501(c)(14)-State Chartered Credit Unions, Mutual Reserve Funds</option>
      <option value="501(c)(15)">501(c)(15)-Mutual Insurance Companies or Associations</option>
      <option value="501(c)(16)">501(c)(16)-Cooperative Organizations to Finance Crop Operations</option>
      <option value="501(c)(17)">501(c)(17)-Supplemental Unemployment Benefit Trusts</option>
      <option value="501(c)(18)">501(c)(18)-Employee Funded Pension Trusts (created before June 25, 1959)</option>
      <option value="501(c)(19)">501(c)(19)-Veterans' Organizations</option>
      <option value="501(c)(21)">501(c)(21)-Black Lung Benefit Trusts</option>
      <option value="501(c)(22)">501(c)(22)-Withdrawal Liability Payment Funds</option>
      <option value="501(c)(25)">501(c)(25)-Title Holding Corporations or Trusts with Multiple Parents</option>
      <option value="501(c)(26)">501(c)(26)-State-Sponsored High-Risk Health Coverage Organizations</option>
      <option value="501(c)(27)">501(c)(27)-State-Sponsored Worker's Compensation Reinsurance Organizations</option>
      <option value="501(c)(28)">501(c)(28)-National Railroad Retirement Investment Trust (45 U.S.C. 231n(j)</option>
      <option value="501(c)(29)">501(c)(29)-Qualified Nonprofit Health Insurance Issuers</option>
      <option value="501(d)">501(d)-Religious and Apostolic Associations</option>
      <option value="501(e)">501(e)-Cooperative Hospital Service Organizations</option>
      <option value="501(f)">501(f)-Cooperative Service Organizations of Operating Educational Organizations</option>
      <option value="501(k)">501(k)-Child Care Organizations</option>
      <option value="521(a)">521(a)-Farmers' Cooperative Associations</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'state'
    },
    optClass: {
      type: String,
      default: 'mb-2'
    },
    value: {
      type: String
    }
  },
}
</script>
