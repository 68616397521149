<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-1">
        <slot>
          <p>Primary contact for your business</p>
        </slot>
      </div>

      <base-input
        name="first-name"
        label="First name"
        v-model="contactFirstName"
        required
      />
      
      <base-input
        name="last-name"
        label="Last name"
        v-model="contactLastName"
        required
      />

      <base-input
        v-if="showTitle"
        name="title"
        label="Title"
        v-model="contactTitle"
        required
      />

      <base-input
        name="email"
        type="email"
        label="Email address"
        v-model="contactEmail"
        required
      />

      <base-input
        name="tel"
        type="tel"
        placeholder="(XXX)-XXX-XXXX"
        maxlength="10"
        label="Phone number"
        v-model="contactTel"
      />
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Contact Information'
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    contactFirstName: {
      get() {
        return store.state.forms.FormContactInfo.firstName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormContactInfo.firstName', value})
      }
    },
    contactLastName: {
      get() {
        return store.state.forms.FormContactInfo.lastName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormContactInfo.lastName', value})
      }
    },
    contactTitle: {
      get() {
        return store.state.forms.FormContactInfo.title
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormContactInfo.title', value})
      }
    },
    contactEmail: {
      get() {
        return store.state.forms.FormContactInfo.email
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormContactInfo.email', value})
      }
    },
    contactTel: {
      get() {
        return store.state.forms.FormContactInfo.tel
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormContactInfo.tel', value})
      }
    },
  }
}
</script>
