<template>
  <b-card>
    <h3 class="text-center">Shipping</h3>

    <!-- first name -->
    <b-row class="form-group">
      <b-col cols="3">First Name</b-col>
      <b-col cols="9">{{shippingInfo('firstName')}}</b-col>
    </b-row>

    <!-- last name -->
    <b-row class="form-group">
      <b-col cols="3">Last Name</b-col>
      <b-col cols="9">{{shippingInfo('lastName')}}</b-col>
    </b-row>

    <!-- Address -->
    <b-row class="form-group">
      <b-col cols="3">Address</b-col>
      <b-col cols="9">{{shippingInfo('address')}}</b-col>
    </b-row>

    <!-- Address 2 -->
    <b-row class="form-group">
      <b-col cols="3">Address2</b-col>
      <b-col cols="9">{{shippingInfo('address2')}}</b-col>
    </b-row>

    <!-- State -->
    <b-row class="form-group">
      <b-col cols="3">State</b-col>
      <b-col cols="9">{{shippingInfo('state')}}</b-col>
    </b-row>

    <!-- ZIP -->
    <b-row class="form-group">
      <b-col cols="3">ZIP</b-col>
      <b-col cols="9">{{shippingInfo('zip')}}</b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardText, BRow, BCol } from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  methods: {
    shippingInfo(key) {
      return store.state.forms.submission.shipping[key]
    },
  },
}
</script>
