<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Recipient Information</h2>
    </div>
    <form>
      <base-input
        name="name"
        label="Recipient Name"
        placeholder="Full Name"
        v-model="receiptName"
        required
      />
      
      <base-input
        type="email"
        name="email"
        label="Email Address"
        v-model="receiptEmail"
        required
      />

      <base-input
        type="tel"
        name="tel"
        opt-class="mb-2"
        label="Telephone Number (optional)"
        maxlength="12"
        v-model="receiptTel"
        required
      />
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store'

export default {
  props: {
    submissionType: String
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(['submitForms']),
    async handleCheckout() {
      try{
        await this.submitForms(this.submissionType)
      }catch(e) {
        this.$notify({
          group: 'general',
          type: 'error',
          text: 'Invalid form data!'
        });
      }
    },
  },
  computed: {
    receiptName: {
      get() {
        return store.state.forms.FormReceiptInfo.name
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormReceiptInfo.name', value})
      }
    },
    receiptEmail: {
      get() {
        return store.state.forms.FormReceiptInfo.email
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormReceiptInfo.email', value})
      }
    },
    receiptTel: {
      get() {
        return store.state.forms.FormReceiptInfo.tel
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormReceiptInfo.tel', value})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.receipt-info {
  a {
    color: #4fb9b9;
  }
}
</style>
