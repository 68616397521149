<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-3">
        <slot>
          <p>What is the name of the company? The state requires the legal name of a company to be unique from other businesses who are registered within the same state. The business name you provide will be researched by one of our agents to ensure that it is available.</p>
        </slot>
      </div>

      <base-input
        name="preferred-company-name"
        label="Preferred Company Name"
        v-model="companyPreferredName"
        required
      />

      <base-input
        name="alt-company-name"
        label="Alternate Company Name"
        v-model="companyAltName"
        required
      />

      <base-input
        name="alt-company-name2"
        label="Alternate Company Name"
        v-model="companyAltName2"
        required
      />

      <select-entity-type v-model="entityType" />

      <select-designator
        v-model="designatorType"
        :entity-type="entityType"
      >
        Select Your Designator
      </select-designator>

      <select-state name="state" v-model="state">
        State of Formation
      </select-state>

      <select-state name="state-applying" v-model="stateApplying">
        State To Obtain Foreign Qualification
      </select-state>

      <!-- <div class="d-flex justify-content-between">
        <button class="button btn-primary" @click.prevent="handleBack">Back</button>
        <button class="button btn-primary" @click.prevent="handleNext">Next</button>
      </div> -->

    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Company Name'
    },
  },
  computed: {
    companyPreferredName: {
      get() {
        return store.state.forms.FormCompanyName.preferredName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.preferredName', value})
      }
    },
    companyAltName: {
      get() {
        return store.state.forms.FormCompanyName.altName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.altName', value})
      }
    },
    companyAltName2: {
      get() {
        return store.state.forms.FormCompanyName.altName2
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.altName2', value})
      }
    },
    entityType: {
      get() {
        return store.state.forms.FormCompanyName.entityType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.entityType', value})
      }
    },
    designatorType: {
      get() {
        return store.state.forms.FormCompanyName.designatorType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.designatorType', value})
      }
    },
    state: {
      get() {
        return store.state.forms.FormCompanyName.state
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.state', value})
      }
    },
    stateApplying: {
      get() {
        return store.state.forms.FormCompanyName.stateApplying
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.stateApplying', value})
      }
    },
  },
  watch: {
    entityType(){
      this.designatorType = ''
    }
  }
}
</script>

<style scoped>
#biz-purpose-example {
  font-size: 12px;
}
</style>
