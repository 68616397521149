<script>
import snakecase from 'lodash.snakecase'

export default {
  methods: {
    change(e) {
      this.$emit('input', e.target.value)
    }
  },
  computed: {
    generatedId() {
      return snakecase(this.name)
    }
  },
}
</script>
