<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <base-input
        name="info"
        :label="orgLabel"
        v-model="organizationName"
        required
      />
      <base-input name="dba"
        label="Trade Name / DBA (optional)"
        opt-class="mb-2"
        v-model="tradeNameOrDBA"
      />
      <base-input name="org-type"
        label="Organization Type (ex: Educational, Sports, HOA, etc)"
        opt-class="mb-2"
        v-if="showOrgType"
        v-model="organizationType"
      />
      <div class="mb-2" v-if="showNumberOfMembers">
        <label for="number-of-members" class="form-label">
          Number of Members / Owners
        </label>
        <select class="form-control" id="number-of-members" v-model="numberOfMembers">
          <option value="">Select an option</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: String,
    orgLabel: String,
    showOrgType: {
      type: Boolean,
      default: false
    },
    showNumberOfMembers: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    organizationName: {
      get() {
        return store.state.forms.FormOrgInfo.organizationName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.organizationName', value})
      }
    },
    tradeNameOrDBA: {
      get() {
        return store.state.forms.FormOrgInfo.tradeNameOrDBA
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.tradeNameOrDBA', value})
      }
    },
    organizationType: {
      get() {
        return this.$store.state.forms.FormOrgInfo.organizationType
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.organizationType', value})
      }
    },
    numberOfMembers: {
      get() {
        return this.$store.state.forms.FormOrgInfo.numberOfMembers
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.numberOfMembers', value})
      }
    },
  }
}
</script>
