<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Withdrawing Your Company</h2>
    </div>
    <form>
      <div class="mb-2">
        <p>Please select the state in which you wish to withdraw your foreign qualified business from and the reason for withdrawal.</p>
      </div>

      <select-state v-model="state" name="state-of-withdrawal">
        Select the state you which to withdraw
      </select-state>

      <div class="mb-2">
        <label for="withdrawal-reason" class="form-label">What is the reason for withdrawal?</label>
        <textarea class="form-control" id="withdrawal-reason" rows="5" v-model="withdrawalReason"></textarea>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    state: {
      get() {
        return store.state.forms.FormWithdrawalInfo.state
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormWithdrawalInfo.state', value})
      }
    },
    withdrawalReason: {
      get() {
        return store.state.forms.FormWithdrawalInfo.withdrawalReason
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormWithdrawalInfo.withdrawalReason', value})
      }
    },
  }
}
</script>
