<template>
  <div class="step-form mx-auto">
    <div class="mb-4 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Business Information</h2>
    </div>
    <form>
      <base-input
        name="company-name"
        label="Company Name"
        v-model="companyName"
        required
      />

      <base-input
        name="title-position"
        label="Title/Position"
        v-model="title"
        required
      />

      <select-state v-model="companyState" opt-class="mb-2">
        State
      </select-state>

      <div class="mb-2">
        <label for="number-of-partners" class="form-label">
          How many states is your nonprofit registered in?
        </label>
        <select class="form-control" id="number-of-partners" v-model="statesRegistered">
          <option value="">Select an option</option>
          <option v-for="n in statesCount" :key="n" :value="n">
            {{n}}
          </option>
        </select>
      </div>

      <div class="mb-2">
        <label for="description" class="form-label">How can we help?</label>
        <textarea class="form-control" id="description" rows="5" v-model="description"></textarea>
      </div>
    </form>
  </div>
</template>


<script>
import range from 'lodash.range'

export default {
  computed: {
    statesCount() {
      return range(1,50)
    },
    companyName: {
      get() {
        return this.$store.state.forms.FormNonprofitInfo.companyName
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormNonprofitInfo.companyName', value})
      }
    },
    title: {
      get() {
        return this.$store.state.forms.FormNonprofitInfo.title
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormNonprofitInfo.title', value})
      }
    },
    companyState: {
      get() {
        return this.$store.state.forms.FormNonprofitInfo.state
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormNonprofitInfo.state', value})
      }
    },
    statesRegistered: {
      get() {
        return this.$store.state.forms.FormNonprofitInfo.statesRegistered
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormNonprofitInfo.statesRegistered', value})
      }
    },
    description: {
      get() {
        return this.$store.state.forms.FormNonprofitInfo.description
      },
      set(value) {
        this.$store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormNonprofitInfo.description', value})
      }
    },
  }
}
</script>
