import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import BaseInput from './views/components/forms/common/BaseInput.vue'
import MemberInfoInput from './views/components/forms/common/MemberInfoInput.vue'
import SelectDesignator from './views/components/forms/common/SelectDesignator.vue'
import SelectCountry from './views/components/forms/common/SelectCountry.vue'
import SelectDate from './views/components/forms/common/SelectDate.vue'
import SelectEntityType from './views/components/forms/common/SelectEntityType.vue'
import SelectMonth from './views/components/forms/common/SelectMonth.vue'
import SelectPrimaryActivity from './views/components/forms/common/SelectPrimaryActivity.vue'
import SelectPrimaryActivityNonprofit from './views/components/forms/common/SelectPrimaryActivityNonprofit.vue'
import SelectReasonForApplying from './views/components/forms/common/SelectReasonForApplying.vue'
import SelectRadio from './views/components/forms/common/SelectRadio.vue'
import SelectRadio2 from './views/components/forms/common/SelectRadio2.vue'
import SelectState from './views/components/forms/common/SelectState.vue'
import SelectStateIR from './views/components/forms/common/SelectStateIR.vue'
import SelectTaxExemption from './views/components/forms/common/SelectTaxExemption.vue'
import SelectYear from './views/components/forms/common/SelectYear.vue'
import SelectYesNoRadio from './views/components/forms/common/SelectYesNoRadio.vue'
import SelectAllCountry from './views/components/forms/common/SelectAllCountry.vue'

import FormAmendInfo from './views/components/forms/FormAmendInfo.vue'
import FormBasicBizInfo from './views/components/forms/FormBasicBizInfo.vue'
import FormBizAddress from './views/components/forms/FormBizAddress.vue'
import FormBizBriefInfo from './views/components/forms/FormBizBriefInfo.vue'
import FormBizBriefInfo2 from './views/components/forms/FormBizBriefInfo2.vue'
import FormBizLicensesPermit from './views/components/forms/FormBizLicensesPermit.vue'
import FormBizName from './views/components/forms/FormBizName.vue'
import FormBizStateIdInfo from './views/components/forms/FormBizStateIdInfo.vue'
import FormCertifiedCopy from './views/components/forms/FormCertifiedCopy.vue'
import FormCompanyInfo from './views/components/forms/FormCompanyInfo.vue'
import FormCompanyName from './views/components/forms/FormCompanyName.vue'
import FormForeignCompanyName from './views/components/forms/FormForeignCompanyName.vue'
import FormCompanyNameExtra from './views/components/forms/FormCompanyNameExtra.vue'
import FormCompanyNameExtra2 from './views/components/forms/FormCompanyNameExtra2.vue'
import FormCompanyNameMerged from './views/components/forms/FormCompanyNameMerged.vue'
import FormCompanyOfficers from './views/components/forms/FormCompanyOfficers.vue'
import FormContactInfo from './views/components/forms/FormContactInfo.vue'
import FormConvertInfo from './views/components/forms/FormConvertInfo.vue'
import FormDateBizAcquired from './views/components/forms/FormDateBizAcquired.vue'
import FormDissolveInfo from './views/components/forms/FormDissolveInfo.vue'
import FormEntityOfficers from './views/components/forms/FormEntityOfficers.vue'
import FormIndividualInfo from './views/components/forms/FormIndividualInfo.vue'
import FormInitialReportOption from './views/components/forms/FormInitialReportOption.vue'
import FormLpAdditionalInfo from './views/components/forms/FormLpAdditionalInfo.vue'
import FormLpBasicInfo from './views/components/forms/FormLpBasicInfo.vue'
import FormNonprofitInfo from './views/components/forms/FormNonprofitInfo.vue'
import FormOrgDirectors from './views/components/forms/FormOrgDirectors.vue'
import FormOrgInfo from './views/components/forms/FormOrgInfo.vue'
import FormOrgInfo2 from './views/components/forms/FormOrgInfo2.vue'
import FormOrgInfo3 from './views/components/forms/FormOrgInfo3.vue'
import FormOrgMembers from './views/components/forms/FormOrgMembers.vue'
import FormOrgPartners from './views/components/forms/FormOrgPartners.vue'
import FormOrgTrust from './views/components/forms/FormOrgTrust.vue'
import FormPersonalInfo from './views/components/forms/FormPersonalInfo.vue'
import FormReceiptInfo from './views/components/forms/FormReceiptInfo.vue'
import FormRegisteredAgent from './views/components/forms/FormRegisteredAgent.vue'
import FormStockInfo from './views/components/forms/FormStockInfo.vue'
import FormSellersPermit from './views/components/forms/FormSellersPermit.vue'
import FormTaxInfo from './views/components/forms/FormTaxInfo.vue'
import FormWithdrawalInfo from './views/components/forms/FormWithdrawalInfo.vue'
import FormWebservice from './views/components/forms/FormWebservice.vue'
import FormTrademark from './views/components/forms/FormTrademark.vue'

import FormTabs from './views/components/tabs/FormTabs.vue'
import FormTab from './views/components/tabs/FormTab.vue'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component('BaseInput', BaseInput)
Vue.component('MemberInfoInput', MemberInfoInput)

Vue.component('SelectEntityType', SelectEntityType)
Vue.component('SelectDesignator', SelectDesignator)

Vue.component('SelectCountry', SelectCountry)

Vue.component('SelectYear', SelectYear)
Vue.component('SelectMonth', SelectMonth)
Vue.component('SelectDate', SelectDate)

Vue.component('SelectRadio', SelectRadio)
Vue.component('SelectRadio2', SelectRadio2)
Vue.component('SelectYesNoRadio', SelectYesNoRadio)
Vue.component('SelectAllCountry', SelectAllCountry)

Vue.component('SelectPrimaryActivity', SelectPrimaryActivity)
Vue.component('SelectPrimaryActivityNonprofit', SelectPrimaryActivityNonprofit)
Vue.component('SelectReasonForApplying', SelectReasonForApplying)
Vue.component('SelectState', SelectState)
Vue.component('SelectStateIR', SelectStateIR)
Vue.component('SelectTaxExemption', SelectTaxExemption)

Vue.component('FormAmendInfo', FormAmendInfo)
Vue.component('FormAmendInfo', FormAmendInfo)
Vue.component('FormBasicBizInfo', FormBasicBizInfo)
Vue.component('FormBizAddress', FormBizAddress)
Vue.component('FormBizBriefInfo', FormBizBriefInfo)
Vue.component('FormBizBriefInfo2', FormBizBriefInfo2)
Vue.component('FormBizLicensesPermit', FormBizLicensesPermit)
Vue.component('FormBizName', FormBizName)
Vue.component('FormBizStateIdInfo', FormBizStateIdInfo)
Vue.component('FormCertifiedCopy', FormCertifiedCopy)
Vue.component('FormCompanyInfo', FormCompanyInfo)
Vue.component('FormCompanyName', FormCompanyName)
Vue.component('FormForeignCompanyName', FormForeignCompanyName)
Vue.component('FormCompanyNameExtra', FormCompanyNameExtra)
Vue.component('FormCompanyNameExtra2', FormCompanyNameExtra2)
Vue.component('FormCompanyNameMerged', FormCompanyNameMerged)
Vue.component('FormCompanyOfficers', FormCompanyOfficers)
Vue.component('FormContactInfo', FormContactInfo)
Vue.component('FormConvertInfo', FormConvertInfo)
Vue.component('FormDateBizAcquired', FormDateBizAcquired)
Vue.component('FormDissolveInfo', FormDissolveInfo)
Vue.component('FormEntityOfficers', FormEntityOfficers)
Vue.component('FormIndividualInfo', FormIndividualInfo)
Vue.component('FormInitialReportOption', FormInitialReportOption)
Vue.component('FormLpAdditionalInfo', FormLpAdditionalInfo)
Vue.component('FormLpBasicInfo', FormLpBasicInfo)
Vue.component('FormNonprofitInfo', FormNonprofitInfo)
Vue.component('FormOrgDirectors', FormOrgDirectors)
Vue.component('FormOrgInfo', FormOrgInfo)
Vue.component('FormOrgInfo2', FormOrgInfo2)
Vue.component('FormOrgInfo3', FormOrgInfo3)
Vue.component('FormOrgMembers', FormOrgMembers)
Vue.component('FormOrgPartners', FormOrgPartners)
Vue.component('FormOrgTrust', FormOrgTrust)
Vue.component('FormPersonalInfo', FormPersonalInfo)
Vue.component('FormReceiptInfo', FormReceiptInfo)
Vue.component('FormRegisteredAgent', FormRegisteredAgent)
Vue.component('FormStockInfo', FormStockInfo)
Vue.component('FormSellersPermit', FormSellersPermit)
Vue.component('FormTaxInfo', FormTaxInfo)
Vue.component('FormWithdrawalInfo', FormWithdrawalInfo)
Vue.component('FormWebservice', FormWebservice)
Vue.component('FormTrademark', FormTrademark)

Vue.component('FormTabs', FormTabs)
Vue.component('FormTab', FormTab)
