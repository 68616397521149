<template>
  <div :class="optClass">
    <label :for="name" class="form-label"><slot>Select Your Business Entity Type</slot></label>
    <select class="form-control" :id="name" @change="change" :disabled="disabled" :value="value">
      <option value="c-corp">C Corporation</option>
      <option value="s-corp">S Corporation</option>
      <option value="llc">Limited Liability Company (LLC)</option>
      <option value="nonprofit">Nonprofit</option>
      <option value="nonprofit-llc">Nonprofit LLC</option>
      <option value="lp">Limited Partnership (LP)</option>
      <option value="llp">Limited Liability Partnership (LLP)</option>
      <option value="lllp">Limited Liability Limited Partnership (LLLP)</option>
      <option value="pcorp">Professional Corporation</option>
      <option value="pllc">Professional LLC</option>
      <option value="close-corp">Close Corporation</option>
      <option value="b-corp">B Corporation</option>
      <option value="gp">General Partnership(GP)</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'entityType'
    },
    optClass: {
      type: String,
      default: 'mb-2'
    },
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
  },
}
</script>