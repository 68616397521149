<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Initial Report</h2>
    </div>
    <div class="mb-3">
      <p>Initial Reports are reports that are filed at the beginning of the formation of a Corporation or LLC, or shortly thereafter. Due to the small number of states that currently require business owners to file Initial Reports for a Corporation, (Alaska, California, Connecticut, Georgia, Louisiana, Missouri, Nevada, New Mexico, South Carolina, and Washington) the initial reporting requirement is often overlooked. By registering your entity with us, we will file your initial report on time to avoid falling out of good standing within your state.</p>
    </div>

    <form>
      <div class="mb-3">
        <p><strong>Do you want StartABizzy.com to file your Initial Report? (Highly Recommended)</strong></p>
        <label class="d-flex align-items-center mr-3" for="use-initial-report">
          <input type="radio" name="initial-report" id="use-initial-report" class="mr-2" @click="handleCheck(true)" :checked="$store.state.forms.FormRegisterOption.needInitialReport">
          <span>Yes, we want StartABizzy to file our Initial Report ($39 Filing Fee + State Fee)</span>
        </label>
        <label class="d-flex align-items-center mr-3" for="no-initial-report">
          <input type="radio" name="initial-report" id="no-initial-report" class="mr-2" @click="handleCheck(false)" :checked="$store.state.forms.FormRegisterOption.needInitialReport===false">
          <span> No, Thank you</span>
        </label>  
      </div>

      <select-state-ir v-model="stateOfInitialReport">What state do you need the Initial Report in?</select-state-ir>
      <div class="mb-4">
        <p>This section is only for applicants who are applying in any of the states mentioned above. If your state was not mentioned please continue on to the next page to complete your order.</p>
      </div>

      <div class="d-flex justify-content-between">
        <button class="button btn-primary" @click.prevent="handleBack">Back</button>
        <button class="button btn-primary" @click.prevent="handleNext">Next</button>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  methods: {
    handleCheck(value) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.needInitialReport', value})
    }
  },
  computed: {
    stateOfInitialReport: {
      get() {
        return store.state.forms.FormRegisterOption.stateOfInitialReport
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormRegisterOption.stateOfInitialReport', value})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
ul#license-service-package-list {
  list-style: disc;
}
</style>
