<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <div class="mb-2">
        <slot>
          <p>Choose the category below that best describes your business. If the categories provided do not describe your business, please select "Other". Then enter your own description in the box provided. Your answers will help us personalize your state formation documents.</p>
        </slot>
      </div>

      <select-primary-activity v-model="companyBizCategory">
        Choose Your Business Category
      </select-primary-activity>

      <div class="mb-2">
        <label for="business-purpose" class="form-label mb-0">Business Purpose</label>
        <p class="mb-0" id="biz-purpose-example">Example: Frozen yogurt shop.</p>
        <textarea class="form-control" id="business-purpose" rows="5" v-model="companyBizPurpose"></textarea>
      </div>

      <div class="mb-2" v-if="showPrimaryActivity">
        <label for="primary-activity-description" class="form-label">If other, please describe your primary business activity</label>
        <textarea class="form-control" id="primary-activity-description" rows="5" v-model="companyPrimaryActivityDescription"></textarea>
      </div>

      <select-yes-no-radio name="isSubsidiaryOfAnother" v-model="isSubsidiaryOfAnother" opt-class="mb-2">Is this a subsidiary of another company? (A subsidiary is a company owned and controlled by another company.)</select-yes-no-radio>
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Business Information'
    },
    showPrimaryActivity: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    companyBizCategory: {
      get() {
        return store.state.forms.FormCompanyName.bizCategory
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.bizCategory', value})
      }
    },
    companyBizPurpose: {
      get() {
        return store.state.forms.FormCompanyName.bizPurpose
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.bizPurpose', value})
      }
    },
    companyPrimaryActivityDescription: {
      get() {
        return store.state.forms.FormCompanyName.primaryActivityDescription
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.primaryActivityDescription', value})
      }
    },
    isSubsidiaryOfAnother: {
      get() {
        return store.state.forms.FormCompanyName.isSubsidiaryOfAnother
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyName.isSubsidiaryOfAnother', value})
      }
    },
  }
}
</script>

<style scoped>
#biz-purpose-example {
  font-size: 12px;
}
</style>
