<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">General Partner(s) or Limited Partner(s)</h2>
    </div>
    <form>
      <div class="mb-2">
        <p>Please enter all partner's name and address below:</p>
      </div>

      <member-info-input
        v-for="id in countRange"
        type="Partner"
        :key="id"
        :id="id"
        :select-partner-type="true"
        @add="addDirector"
        @delete="deleteDirector"
      />
    </form>
  </div>
</template>

<script>
import range from 'lodash.range'
import store from '@/store'

export default {
  props: {
    title: String
  },
  methods: {
    deleteDirector(id) {
      store.commit(
        'forms/DELETE_ITEM_IN_FORM_ARRAY',
        {
          type: 'FormOrgMember',
          at: id
        }
      )
    },
    addDirector() {
      store.commit(
        'forms/ADD_NEW_ITEM_IN_FORM_ARRAY', 
        {
          type: 'FormOrgMember',
          additional: {
            memberType: 'general-partner'
          }
        }
      )
    }
  },
  computed: {
    countRange() {
      return range(store.state.forms.FormOrgMember.length)
    },
  },
  created() {
    // store.commit('forms/CHANGE_ORG_ALL_MEMBER_TYPE', 'general-partner')
  }
}
</script>