<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-8 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-4 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Summary</span>
      </template>
      
      <submission-summary />
    </b-tab>
    <b-tab v-if="shipping">
      <template #title>
        <feather-icon
          icon="BriefcaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Shipping</span>
      </template>
      
      <shipping-info />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Process</span>
      </template>
      
      <submission-process />
    </b-tab>
    <b-tab title-link-class="mb-1">
      <template #title>
        <feather-icon
          icon="ShareIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Attachments</span>
      </template>
      
      <attachments />
    </b-tab>

    <slot />
  </b-tabs>
</template>

<script>
import store from '@/store'
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import SubmissionSummary from './SubmissionSummary'
import ShippingInfo from './ShippingInfo'
import SubmissionProcess from './SubmissionProcess'
import Attachments from './Attachments'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    SubmissionSummary,
    ShippingInfo,
    SubmissionProcess,
    Attachments
  },
  computed: {
    shipping() {
      return store.state.forms.submission.shipping
    }
  }
}
</script>
