<template>
  <div :class="optClass">
    <label :for="generatedId" class="form-label"><slot>Primary Activity</slot></label>
    <select class="form-control" :id="generatedId" @change="change" :value="value">
      <option value="">Select an option</option>
      <option value="religious">Religious</option>
      <option value="charitable">Charitable</option>
      <option value="scientific">Scientific</option>
      <option value="public-safety">Testing for Public Safety</option>
      <option value="literary">Literary</option>
      <option value="educational">Educational</option>
      <option value="cruelty-of-children-animals">Preventing Cruelty to Children or Animals</option>
      <option value="other">Other</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'primaryActivity'
    },
    optClass: {
      type: String,
      default: 'mb-2'
    },
    value: String
  },
}
</script>