<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <base-input name="dba"
        label="Trade Name / DBA (optional)"
        opt-class="mb-4"
        v-model="tradeNameOrDBA"
      />
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: String,
  },
  computed: {
    tradeNameOrDBA: {
      get() {
        return store.state.forms.FormOrgInfo.tradeNameOrDBA
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.tradeNameOrDBA', value})
      }
    }
  }
}
</script>
