import pick from 'lodash.pick'
import jwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    token: null,
    user: null,
  },
  getters: {},
  mutations: {
    SET_TOKEN(state, payload) {
      state.loggedIn = true
      state.token = payload
    },
    SET_USER_INFO(state, payload) {
      state.user = payload
    },
    REMOVE_TOKEN(state) {
      state.loggedIn = false
      state.token = ''
      state.user = null
    },
  },
  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        jwt.login(payload)
          .then(({data}) => {
            if (data.user.role === 'admin') {
              jwt.setToken(data.user.token)
              commit('SET_TOKEN', data.user.token)
              commit('SET_USER_INFO', pick(data.user, ['email', 'role']))
              resolve()
            } else {
              reject()
            }
          }).catch(reject)
      })
    },
    logout({ commit }) {
      jwt.deleteToken()
      commit('REMOVE_TOKEN')
    },
  },
}
