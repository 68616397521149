<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600"><slot>Business State ID (File) Number</slot></h2>
    </div>
    <form>
      <div class="mb-2">
        <p>If you know your businesses <strong>State ID (File) Number</strong> that was issued by the Secretary of State, please enter it below. If you do not have this number, that's okay, you may skip this step.</p>
      </div>

      <base-input
        name="state-id"
        label="State ID (File) Number (optional)"
        opt-class="mb-2"
        v-model="bizStateId"
        required
      />
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    bizStateId: {
      get() {
        return store.state.forms.FormBizStateIdInfo.stateId
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBizStateIdInfo.stateId', value})
      }
    },
  }
}
</script>
