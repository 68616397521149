
const resolveSubmissionStatusVariant = status => {
  if (status === 'draft') return 'secondary'
  if (status === 'pending') return 'secondary'
  if (status === 'checked-out') return 'warning'
  if (status === 'free') return 'warning'

  if (status === 'accepted') return 'primary'
  if (status === 'approved') return 'primary'
  if (status === 'submitted') return 'success'
  
  if (status === 'completed') return 'dark'
  if (status === 'rejected') return 'danger'
  
  return 'info'
}

const resolveProcessRecordVariant = (actionType) => {
  if (actionType === 'checked-out') return 'warning'
  if (actionType === 'accepted') return 'primary'
  if (actionType === 'approved') return 'primary'
  if (actionType === 'submitted') return 'success'
  if (actionType === 'completed') return 'dark'

  return 'info'
}

/**
 *  User Role
 */
const userRoleOptions = [
  { label: 'Admin', value: 'admin' },
  { label: 'Client', value: 'client' },
]
const resolveUserRoleVariant = role => {
  if (role === 'client') return 'secondary'
  if (role === 'admin') return 'danger'
  return 'primary'
}
const resolveUserRoleIcon = role => {
  if (role === 'client') return 'UserIcon'
  if (role === 'admin') return 'SettingsIcon'
  
  return 'UserIcon'
}

/**
 *  User Status
 */
const userStatusOptions = [
  { label: 'Active', value: 'active'},
  { label: 'Inactive', value: 'inactive'}
]
const resolveUserStatusVariant = status => {
  if (status === 'active') return 'primary'
  return 'secondary'
}

export {
  resolveSubmissionStatusVariant,
  resolveProcessRecordVariant,

  userRoleOptions,
  resolveUserRoleVariant,
  resolveUserRoleIcon,

  userStatusOptions,
  resolveUserStatusVariant
}
