<template>
  <div :class="optClass">
    <label :for="generatedId" class="form-label"><slot>Country</slot></label>
    <div class="select">
      <select class="form-control" :id="generatedId" @change="change" :value="value">
        <option value="">Select an option</option>
        <option value="USX">United States</option>
        <option value="AFX">Afghanistan</option>
        <option value="ALX">Albania</option>
        <option value="DZX">Algeria</option>
        <option value="ASX">American Samoa</option>
        <option value="ADX">Andorra</option>
        <option value="AOX">Angola</option>
        <option value="AIX">Anguilla</option>
        <option value="AGX">Antigua and Barbuda</option>
        <option value="ARX">Argentina</option>
        <option value="AMX">Armenia</option>
        <option value="AWX">Aruba</option>
        <option value="AUX">Australia</option>
        <option value="ATX">Austria</option>
        <option value="AZX">Azerbaijan</option>
        <option value="BSX">Bahamas</option>
        <option value="BHX">Bahrain</option>
        <option value="BDX">Bangladesh</option>
        <option value="BBX">Barbados</option>
        <option value="BYX">Belarus</option>
        <option value="BEX">Belgium</option>
        <option value="BZX">Belize</option>
        <option value="BJX">Benin</option>
        <option value="BMX">Bermuda</option>
        <option value="BQX">BES Islands</option>
        <option value="BTX">Bhutan</option>
        <option value="BOX">Bolivia</option>
        <option value="BAX">Bosnia and Herzegovina</option>
        <option value="BWX">Botswana</option>
        <option value="BVX">Bouvet Island</option>
        <option value="BRX">Brazil</option>
        <option value="IOX">British Indian Ocean Territory</option>
        <option value="VGX">British Virgin Islands</option>
        <option value="BNX">Brunei Darussalam</option>
        <option value="BGX">Bulgaria</option>
        <option value="BFX">Burkina Faso</option>
        <option value="BIX">Burundi</option>
        <option value="CIX">Côte d'Ivoire</option>
        <option value="KHX">Cambodia</option>
        <option value="CMX">Cameroon</option>
        <option value="CAX">Canada</option>
        <option value="CVX">Cabo Verde</option>
        <option value="KYX">Cayman Islands</option>
        <option value="CFX">Centralican Republic</option>
        <option value="TDX">Chad</option>
        <option value="CLX">Chile</option>
        <option value="CNX">China</option>
        <option value="CXX">Christmas Island</option>
        <option value="CCX">Cocos Islands</option>
        <option value="COX">Colombia</option>
        <option value="KMX">Comoros</option>
        <option value="CGX">Congo</option>
        <option value="CKX">Cook Islands</option>
        <option value="CRX">Costa Rica</option>
        <option value="HRX">Croatia</option>
        <option value="CUX">Cuba</option>
        <option value="CWX">Curacao</option>
        <option value="CYX">Cyprus</option>
        <option value="CZX">Czech Republic</option>
        <option value="DKX">Denmark</option>
        <option value="DJX">Djibouti</option>
        <option value="DMX">Dominica</option>
        <option value="DOX">Dominican Republic</option>
        <option value="ECX">Ecuador</option>
        <option value="EGX">Egypt</option>
        <option value="SVX">El Salvador</option>
        <option value="GB2">England</option>
        <option value="GQX">Equatorial Guinea</option>
        <option value="ERX">Eritrea</option>
        <option value="EEX">Estonia</option>
        <option value="ETX">Ethiopia</option>
        <option value="FKX">Falkland Islands</option>
        <option value="FOX">Faroe Islands</option>
        <option value="FJX">Fiji</option>
        <option value="FIX">Finland</option>
        <option value="FRX">France</option>
        <option value="GFX">French Guiana</option>
        <option value="PFX">French Polynesia</option>
        <option value="GAX">Gabon</option>
        <option value="GMX">Gambia</option>
        <option value="GEX">Georgia</option>
        <option value="DEX">Germany</option>
        <option value="GHX">Ghana</option>
        <option value="GIX">Gibraltar</option>
        <option value="GB3">Great Britain</option>
        <option value="GRX">Greece</option>
        <option value="GLX">Greenland</option>
        <option value="GDX">Grenada</option>
        <option value="GPX">Guadeloupe</option>
        <option value="GUX">Guam</option>
        <option value="GTX">Guatemala</option>
        <option value="GGX">Guernsey</option>
        <option value="GNX">Guinea</option>
        <option value="GWX">Guinea-Bissau</option>
        <option value="GYX">Guyana</option>
        <option value="HTX">Haiti</option>
        <option value="HMX">Heard Island and McDonald Islands</option>
        <option value="HNX">Honduras</option>
        <option value="HKX">Hong Kong</option>
        <option value="HUX">Hungary</option>
        <option value="ISX">Iceland</option>
        <option value="INX">India</option>
        <option value="IDX">Indonesia</option>
        <option value="IRX">Iran</option>
        <option value="IQX">Iraq</option>
        <option value="IEX">Ireland</option>
        <option value="GB4">Isle of Man</option>
        <option value="ILX">Israel</option>
        <option value="ITX">Italy</option>
        <option value="JMX">Jamaica</option>
        <option value="JPX">Japan</option>
        <option value="JEX">Jersey</option>
        <option value="JTX">Johnston Atoll</option>
        <option value="JOX">Jordan</option>
        <option value="KZX">Kazakhstan</option>
        <option value="KEX">Kenya</option>
        <option value="KIX">Kiribati</option>
        <option value="KPX">Korea, North</option>
        <option value="KRX">Korea, South</option>
        <option value="KWX">Kuwait</option>
        <option value="KGX">Kyrgyzstan</option>
        <option value="LAX">Laos</option>
        <option value="LVX">Latvia</option>
        <option value="LBX">Lebanon</option>
        <option value="LSX">Lesotho</option>
        <option value="LRX">Liberia</option>
        <option value="LYX">Libya</option>
        <option value="LIX">Liechtenstein</option>
        <option value="LTX">Lithuania</option>
        <option value="LUX">Luxembourg</option>
        <option value="MOX">Macau</option>
        <option value="MKX">Macedonia</option>
        <option value="MGX">Madagascar</option>
        <option value="MWX">Malawi</option>
        <option value="MYX">Malaysia</option>
        <option value="MVX">Maldives</option>
        <option value="MLX">Mali</option>
        <option value="MTX">Malta</option>
        <option value="MHX">Marshall Islands</option>
        <option value="MQX">Martinique</option>
        <option value="MRX">Mauritania</option>
        <option value="MUX">Mauritius</option>
        <option value="YTX">Mayotte</option>
        <option value="MXX">Mexico</option>
        <option value="FMX">Micronesia</option>
        <option value="MDX">Moldova</option>
        <option value="MCX">Monaco</option>
        <option value="MNX">Mongolia</option>
        <option value="MSX">Montserrat</option>
        <option value="MAX">Morocco</option>
        <option value="MEX">Montenegro</option>
        <option value="MZX">Mozambique</option>
        <option value="MMX">Myanmar</option>
        <option value="NAX">Namibia</option>
        <option value="NRX">Nauru</option>
        <option value="NVX">Navassa Island</option>
        <option value="NPX">Nepal</option>
        <option value="NLX">Netherlands</option>
        <option value="NZX">New Zealand</option>
        <option value="NIX">Nicaragua</option>
        <option value="NEX">Niger</option>
        <option value="NGX">Nigeria</option>
        <option value="GB5">Northern Ireland</option>
        <option value="MPX">Northern Mariana Islands</option>
        <option value="NOX">Norway</option>
        <option value="OMX">Oman</option>
        <option value="PKX">Pakistan</option>
        <option value="PWX">Palau</option>
        <option value="PAX">Panama</option>
        <option value="PGX">Papua New Guinea</option>
        <option value="PYX">Paraguay</option>
        <option value="PEX">Peru</option>
        <option value="PHX">Philippines</option>
        <option value="PLX">Poland</option>
        <option value="PTX">Portugal</option>
        <option value="PRX">Puerto Rico</option>
        <option value="QAX">Qatar</option>
        <option value="ROX">Romania</option>
        <option value="RUX">Russia</option>
        <option value="RWX">Rwanda</option>
        <option value="KNX">Saint Kitts and Nevis</option>
        <option value="LCX">Saint Lucia</option>
        <option value="VCX">Saint Vincent and Grenadines</option>
        <option value="OOX">Samoa</option>
        <option value="SMX">San Marino</option>
        <option value="STX">Sao Tome and Principe</option>
        <option value="SAX">Saudi Arabia</option>
        <option value="GB6">Scotland</option>
        <option value="SNX">Senegal</option>
        <option value="RSX">Serbia</option>
        <option value="SCX">Seychelles</option>
        <option value="SLX">Sierra Leone</option>
        <option value="SGX">Singapore</option>
        <option value="SKX">Slovakia</option>
        <option value="SIX">Slovenia</option>
        <option value="SBX">Solomon Islands</option>
        <option value="SOX">Somalia</option>
        <option value="ZAX">South Africa</option>
        <option value="ESX">Spain</option>
        <option value="LKX">Sri Lanka</option>
        <option value="SXX">St. Maarten</option>
        <option value="SDX">Sudan</option>
        <option value="SRX">Suriname</option>
        <option value="SZX">Swaziland</option>
        <option value="SEX">Sweden</option>
        <option value="CHX">Switzerland</option>
        <option value="SYX">Syria</option>
        <option value="TWX">Taiwan</option>
        <option value="TJX">Tajikistan</option>
        <option value="TZX">Tanzania</option>
        <option value="THX">Thailand</option>
        <option value="TLX">Timor-Leste</option>
        <option value="TGX">Togo</option>
        <option value="TOX">Tonga</option>
        <option value="TTX">Trinidad and Tobago</option>
        <option value="TNX">Tunisia</option>
        <option value="TRX">Turkey</option>
        <option value="TMX">Turkmenistan</option>
        <option value="TCX">Turks/Caicos Islands</option>
        <option value="TVX">Tuvalu</option>
        <option value="UGX">Uganda</option>
        <option value="UAX">Ukraine</option>
        <option value="AEX">United Arab Emirates</option>
        <option value="GBX">United Kingdom</option>
        <option value="USA">United States</option>
        <option value="UYX">Uruguay</option>
        <option value="VIX">US Virgin Islands</option>
        <option value="UZX">Uzbekistan</option>
        <option value="VUX">Vanuatu</option>
        <option value="VAX">Vatican City</option>
        <option value="VEX">Venezuela</option>
        <option value="VNX">Vietnam</option>
        <option value="WKX">Wake Island</option>
        <option value="GB7">Wales</option>
        <option value="WFX">Wallis and Futuna</option>
        <option value="PSX">West Bank/Gaza</option>
        <option value="EHX">Western Sahara</option>
        <option value="YEX">Yemen</option>
        <option value="ZMX">Zambia</option>
        <option value="ZWX">Zimbabwe</option>
        </select>
    </div>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'country'
    },
    optClass: {
      type: String,
      default: 'mb-3'
    },
    value: {
      type: String
    },
    rules: {
      type: String,
      default: ''
    }
  },
}
</script>