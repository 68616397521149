<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">{{title}}</h2>
    </div>
    <form>
      <base-input
        name="owner1"
        label="Owner #1"
        placeholder="Full Name"
        v-model="president"
        required
      />
      
      <base-input
        name="owner2"
        label="Owner #2"
        placeholder="Full Name"
        v-model="treasurer"
        required
      />

      <base-input
        name="owner3"
        label="Owner #3"
        placeholder="Full Name"
        v-model="secretary"
        required
      />

      <base-input
        name="owner4"
        label="Owner #4"
        placeholder="Full Name"
        v-model="vicePresident"
      />
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Full Name of Individual or Entity?'
    }
  },
  computed: {
    president: {
      get() {
        return store.state.forms.FormCompanyOfficers.president
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.president', value})
      }
    },
    treasurer: {
      get() {
        return store.state.forms.FormCompanyOfficers.treasurer
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.treasurer', value})
      }
    },
    secretary: {
      get() {
        return store.state.forms.FormCompanyOfficers.secretary
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.secretary', value})
      }
    },
    vicePresident: {
      get() {
        return store.state.forms.FormCompanyOfficers.vicePresident
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCompanyOfficers.vicePresident', value})
      }
    }
  }
}
</script>
