import axios from '@axios'
import users from './users'
import submissions from './submissions'
import processRecords from './processRecords'

const $http = axios

export default {
  users: users({$http}),
  submissions: submissions({$http}),
  processRecords: processRecords({$http})
}
