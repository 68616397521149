<template>
  <div :class="optClass">
    <!-- Question content -->
    <p class="mb-0">
      <strong>
        <slot></slot>
      </strong>
    </p>
    <!-- radios -->
    <div class="d-flex align-items-center">
      <label class="d-flex align-items-center mr-3" v-for="(item, index) in items" :for="'radio-' + name + index" :key="index">
        <input type="radio" :name="name" :id="'radio-' + name + index" class="mr-2" @click="$emit('input', item.value)" :checked="value===item.value">
        <span>{{item.name}}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optClass: {
      type: String,
      default: 'mb-3'
    },
    name: String,
    value: String | Number,
    items: Array
  },
}
</script>
