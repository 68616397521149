import axios from '@axios'

const getPresignedUrl = async (file) => {
  try {
    // in backend, it also checks file size.
    let {data} = await axios.post(
      '/attachments/presign',
      {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size
      }
    )
    return data
  } catch(e) {
    console.log('error in presign-url: ', e)
    return null
  }
}

const uploadFileToAws = (url, file) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', url)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(true)
        } else {
          resolve(false)
        }
      }
    }
    xhr.send(file)
  })
}

const applyS3DirectUpload = async (file) => {
  let presignedInfo = await getPresignedUrl(file)
  if (!presignedInfo) {
    return false
  }
  let data = await uploadFileToAws(presignedInfo.signedRequest, file)
  return data && presignedInfo.url
}

export default applyS3DirectUpload
