<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Basic Business Information</h2>
    </div>
    <form>
      <div v-if="isBusiness">
        <select-country v-model="bizCountryOfOrigin">Country of Origin</select-country>
        <select-state name="stateOfOrigin" v-model="bizStateOfOrigin">State of Origin</select-state>

        <select-reason-for-applying v-model="bizReasonForApplying" />
        <select-primary-activity v-model="bizPrimaryActivity" />

        <div class="mb-3">
          <label for="specificProductsOrServices" class="form-label">Specific Products or Services</label>
          <textarea class="form-control" id="specificProductsOrServices" rows="5" v-model="bizSpecificProductsOrServices"></textarea>
        </div>

        <select-yes-no-radio name="motorGt55K" v-model="bizOwnMotorVehicle55KMore">Does the business own a highway motor vehicle weighing 55,000 pounds or more?</select-yes-no-radio>
        <select-yes-no-radio name="involveGambling" v-model="bizInvolveGambling">Does the business involve gambling?</select-yes-no-radio>
        <select-yes-no-radio name="sellAlcoholTobacco" v-model="bizSellAlcoholTobaccoFirearm">Does the business sell or manufacture alcohol, tobacco, or firearms?</select-yes-no-radio>
        <select-yes-no-radio name="payFedExTax" v-model="bizPayFedExciseTax">Does your business pay federal excise taxes?</select-yes-no-radio>
        <select-yes-no-radio name="payLt4K" v-model="bizWishAnnualFileUnder4Kwages">If you expect to pay less than $4,000 in wages over the next calendar year, do you wish to file annual instead of quarterly taxes?</select-yes-no-radio>

        <div class="border-bottom mb-3"></div>
      </div>

      <select-yes-no-radio name="appliedEinBefore" v-model="bizAppliedEinBefore">Has this entity applied for an EIN before?</select-yes-no-radio>
      
      <div class="mb-3" v-show="bizAppliedEinBefore">
        <label for="specificProductsOrServices" class="form-label"><strong>Previous Federal Tax ID</strong></label>
        <input type="text" id="tax" name="tax" class="form-control" v-model="bizPreviousFedTaxId" required>
      </div>

      <select-yes-no-radio name="hireEmployeeIn12Months" v-model="bizHireEmployeeIn12Months">Do you already have or expect to hire an employee within 12 months, excluding owners?</select-yes-no-radio>

      <div v-show="bizHireEmployeeIn12Months">
        <select-yes-no-radio name="bizEmpTaxLiability1K" v-model="bizEmpTaxLiability1K">Do you expect your employment tax liability to be $1,000 or less in a full calendar year (January-December)(check if yes)?</select-yes-no-radio>  

        <select-month v-model="bizEmpTaxLiability1KMonth" />
        <select-date v-model="bizEmpTaxLiability1KDate" />
        <select-year opt-class="mb-2" v-model="bizEmpTaxLiability1KYear" />
        
        <div class="mb-1">
          <label for="bizNumberOfAgriculEmployee" class="form-label"><strong>Number of Agricultural Employees (optional)</strong></label>
          <input type="text" id="bizNumberOfAgriculEmployee" name="bizNumberOfAgriculEmployee" class="form-control" v-model="bizNumberOfAgriculEmployee">
        </div>

        <div class="mb-1">
          <label for="bizNumberOfHouseholdEmployee" class="form-label"><strong>Number of Household Employees (optional)</strong></label>
          <input type="text" id="bizNumberOfHouseholdEmployee" name="bizNumberOfHouseholdEmployee" class="form-control" v-model="bizNumberOfHouseholdEmployee">
        </div>

        <div class="mb-1">
          <label for="bizNumberOfOtherEmployee" class="form-label"><strong>Other Employees (optional)</strong></label>
          <input type="text" id="bizNumberOfOtherEmployee" name="bizNumberOfOtherEmployee" class="form-control" v-model="bizNumberOfOtherEmployee">
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    isBusiness: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    bizCountryOfOrigin: {
      get() {
        return store.state.forms.FormBasicBizInfo.countryOfOrigin
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.countryOfOrigin', value})
      }
    },
    bizStateOfOrigin: {
      get() {
        return store.state.forms.FormBasicBizInfo.stateOfOrigin
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.stateOfOrigin', value})
      }
    },
    bizReasonForApplying: {
      get() {
        return store.state.forms.FormBasicBizInfo.reasonForApplying
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.reasonForApplying', value})
      }
    },
    bizPrimaryActivity: {
      get() {
        return store.state.forms.FormBasicBizInfo.primaryActivity
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.primaryActivity', value})
      }
    },
    bizSpecificProductsOrServices: {
      get() {
        return store.state.forms.FormBasicBizInfo.specificProductsOrServices
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.specificProductsOrServices', value})
      }
    },
    bizOwnMotorVehicle55KMore: {
      get() {
        return store.state.forms.FormBasicBizInfo.hasMotorVehicle55KMore
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.hasMotorVehicle55KMore', value})
      }
    },
    bizInvolveGambling: {
      get() {
        return store.state.forms.FormBasicBizInfo.involveGambling
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.involveGambling', value})
      }
    },
    bizSellAlcoholTobaccoFirearm: {
      get() {
        return store.state.forms.FormBasicBizInfo.sellAlcholTobaccoFirearm
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.sellAlcholTobaccoFirearm', value})
      }
    },
    bizPayFedExciseTax: {
      get() {
        return store.state.forms.FormBasicBizInfo.payFedExciseTax
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.payFedExciseTax', value})
      }
    },
    bizWishAnnualFileUnder4Kwages: {
      get() {
        return store.state.forms.FormBasicBizInfo.wishAnnualFileUnder4Kwages
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.wishAnnualFileUnder4Kwages', value})
      }
    },
    bizAppliedEinBefore: {
      get() {
        return store.state.forms.FormBasicBizInfo.appliedEinBefore
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.appliedEinBefore', value})
      }
    },
    bizHireEmployeeIn12Months: {
      get() {
        return store.state.forms.FormBasicBizInfo.hireEmployeeIn12Months
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.hireEmployeeIn12Months', value})
      }
    },
    bizPreviousFedTaxId: {
      get() {
        return store.state.forms.FormBasicBizInfo.previousFedTaxId
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.previousFedTaxId', value})
      }
    },
    bizEmpTaxLiability1K: {
      get() {
        return store.state.forms.FormBasicBizInfo.bizEmpTaxLiability1K
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.bizEmpTaxLiability1K', value})
      }
    },
    bizNumberOfAgriculEmployee: {
      get() {
        return store.state.forms.FormBasicBizInfo.numberOfAgriculEmployee
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.numberOfAgriculEmployee', value})
      }
    },
    bizNumberOfHouseholdEmployee: {
      get() {
        return store.state.forms.FormBasicBizInfo.numberOfHouseholdEmployee
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.numberOfHouseholdEmployee', value})
      }
    },
    bizNumberOfOtherEmployee: {
      get() {
        return store.state.forms.FormBasicBizInfo.numberOfOtherEmployee
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.numberOfOtherEmployee', value})
      }
    },
    bizEmpTaxLiability1KYear: {
      get() {
        return `${new Date(store.state.forms.FormBasicBizInfo.bizEmpTaxLiability1KFullDate).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormBasicBizInfo.bizEmpTaxLiability1KFullDate)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.bizEmpTaxLiability1KFullDate', value: fullDate})
      }
    },
    bizEmpTaxLiability1KMonth: {
      get() {
        return `${new Date(store.state.forms.FormBasicBizInfo.bizEmpTaxLiability1KFullDate).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormBasicBizInfo.bizEmpTaxLiability1KFullDate)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.bizEmpTaxLiability1KFullDate', value: fullDate})
      }
    },
    bizEmpTaxLiability1KDate: {
      get() {
        return `${new Date(store.state.forms.FormBasicBizInfo.bizEmpTaxLiability1KFullDate).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormBasicBizInfo.bizEmpTaxLiability1KFullDate)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormBasicBizInfo.bizEmpTaxLiability1KFullDate', value: fullDate})
      }
    }
  }
}
</script>
