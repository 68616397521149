export default ({$http}) => ({
  fetchSubmissions: (queryParams) => {
    return new Promise((resolve, reject) => {
      $http
        .get('/admin/submissions', { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchSubmission: (id) => {
    return new Promise((resolve, reject) => {
      $http
        .get('/admin/submissions/'+id)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  assignStaff: (submission, assignee) => {
    return new Promise((resolve, reject) => {
      $http
        .post('/admin/submissions/'+submission+'/assign', {assignee})
        .then(() => resolve())
        .catch(() => reject())
    })
  },
  addProcessRecord: (submission, record) => {
    return new Promise((resolve, reject) => {
      $http
        .post('/admin/submissions/'+submission+'/process-records', {record})
        .then(({data}) => {
          resolve(data.result)
        })
        .catch(() => {
          reject()
        })
    })
  },
  attachFile: (submission, payload) => {
    return new Promise((resolve, reject) => {
      $http
        .post('/admin/submissions/'+submission+'/attachments', payload)
        .then(({data}) => {
          resolve(data)
        })
        .catch(() => {
          reject()
        })
    })
  }
})
