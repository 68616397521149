<template>
  <div :class="optClass">
    <label :for="generatedId" class="form-label"><slot>Primary Activity</slot></label>
    <select class="form-control" :id="generatedId" @change="change" :value="value">
      <option value="">Select an option</option>
      <option value="Accommodation/Food Service">Accommodation/Food Service</option>
      <option value="Rental Leasing">Rental Leasing</option>
      <option value="Warehouse">Warehouse</option>
      <option value="Construction">Construction</option>
      <option value="Finances">Finances</option>
      <option value="Food Services">Food Services</option>
      <option value="Health Care">Health Care</option>
      <option value="Insurance">Insurance</option>
      <option value="Manufacturing">Manufacturing</option>
      <option value="Retail">Retail</option>
      <option value="Real Estate">Real Estate</option>
      <option value="Transportation">Transportation</option>
      <option value="Wholesale/Agent/Broker">Wholesale/Agent/Broker</option>
      <option value="Wholesale/Other">Wholesale/Other</option>
      <option value="Other">Other</option>
    </select>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect.vue'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'primaryActivity'
    },
    optClass: {
      type: String,
      default: 'mb-1'
    },
    value: String
  },
}
</script>