<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Trust Information</h2>
    </div>
    <form>
      <base-input
        name="info"
        :label="orgLabel"
        v-model="organizationName"
        required
      />
      
      <div class="mb-2">
        <label for="trust-type" class="form-label">Type of Trust</label>
        <select class="form-control" id="trust-type" v-model="tradeNameOrDBA">
          <option value="">Select an option</option>
          <option value="bankruptcy-estate">Bankruptcy Estate (Individual)</option>
          <option value="charitable-lead-annuity-trust">Charitable Lead Annuity Trust</option>
          <option value="charitable-lead-unitrust">Charitable Lead Unitrust</option>
          <option value="charitable-remainder-annuity-trust">Charitable Remainder Annuity Trust</option>
          <option value="charitable-remainder-unitrust">Charitable Remainder Unitrust</option>
          <option value="conservatorship">Conservatorship</option>
          <option value="custodianship">Custodianship</option>
          <option value="escrow">Escrow</option>
          <option value="FNMA">FNMA (Fannie MAE)</option>
          <option value="GNMA">GNMA (Ginnie MAE)</option>
          <option value="guardianship">Guardianship</option>
          <option value="irrevocable Trust">Irrevocable Trust</option>
          <option value="pooled-income-fund">Pooled Income Fund</option>
          <option value="qualified-funeral-trust">Qualified Funeral Trust</option>
          <option value="receivership">Receivership</option>
          <option value="revocable-trust">Revocable Trust</option>
          <option value="settlement-fund">Settlement Fund</option>
          <option value="trust">Trust (All Others)</option>
        </select>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: String,
    orgLabel: String,
  },
  computed: {
    organizationName: {
      get() {
        return store.state.forms.FormOrgInfo.organizationName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.organizationName', value})
      }
    },
    tradeNameOrDBA: {
      get() {
        return store.state.forms.FormOrgInfo.tradeNameOrDBA
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormOrgInfo.tradeNameOrDBA', value})
      }
    }
  }
}
</script>
