<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">EIN / Federal Tax ID</h2>
    </div>
    <div class="mb-3">
      <label><strong>What is a EIN/ Federal Tax ID?</strong></label>
      <p>Before your business can open a bank account, file taxes, collect revenue, or hire employees, you will need an Employer Identification Number (EIN), or what is sometimes referred to as a Federal Tax ID number.</p>
    </div>

    <form>
      <select-yes-no-radio name="bizNeedEinTaxID" v-model="bizNeedEinTaxID" opt-class="mb-0">Do you need a EIN/ Tax ID? (Free and Highly Recommended)</select-yes-no-radio>
      <p>We'd be happy to get you a EIN/ Federal Tax ID! To do so, we will need the Social Security Number of the applicant who is filing for the business.</p>

      <base-input
        name="ssn"
        label="Social Security Number🔒"
        placeholder="[XXX-XX-XXX]"
        maxlength="9"
        v-model="bizSSN"
        required
      />

      <p>By providing this information, you understand that you are authorizing “StartABizzy.com, LLC” to act as a third party designee, (the “Third Party Designee”). The “Third Party Designee” will apply and provide your information to the IRS on your behalf.</p>

      <select-yes-no-radio name="motorGt55K" v-model="bizOwnMotorVehicle55KMore">Does the business own a highway motor vehicle weighing 55,000 pounds or more?</select-yes-no-radio>
      <select-yes-no-radio name="involveGambling" v-model="bizInvolveGambling">Does the business involve gambling?</select-yes-no-radio>
      <select-yes-no-radio name="sellAlcoholTobacco" v-model="bizSellAlcoholTobaccoFirearm">Does the business sell or manufacture alcohol, tobacco, or firearms?</select-yes-no-radio>
      <select-yes-no-radio name="payFedExTax" v-model="bizPayFedExciseTax">Does your business pay federal excise taxes?</select-yes-no-radio>
      <select-yes-no-radio name="payLt4K" v-model="bizWishAnnualFileUnder4Kwages">If you expect to pay less than $4,000 in wages over the next calendar year, do you wish to file annual instead of quarterly taxes?</select-yes-no-radio>

      <div class="border-bottom mb-3"></div>

      <select-yes-no-radio name="appliedEinBefore" v-model="bizAppliedEinBefore">Has this entity applied for an EIN before?</select-yes-no-radio>
      
      <base-input
        v-if="bizAppliedEinBefore"
        name="prev-fed-tax-id"
        label="Previous Federal Tax ID"
        v-model="bizPreviousFedTaxId"
        required
      />

      <select-yes-no-radio name="hireEmployeeIn12Months" v-model="bizHireEmployeeIn12Months">Do you already have or expect to hire an employee within 12 months, excluding owners?</select-yes-no-radio>

      <div v-if="bizHireEmployeeIn12Months">
        <select-yes-no-radio name="bizEmpTaxLiability1K" v-model="bizEmpTaxLiability1K">Do you expect your employment tax liability to be $1,000 or less in a full calendar year (January-December)(check if yes)?</select-yes-no-radio>  

        <select-month v-model="bizEmpTaxLiability1KMonth" />
        <select-date v-model="bizEmpTaxLiability1KDate" />
        <select-year opt-class="mb-5" v-model="bizEmpTaxLiability1KYear" />
        
        <base-input
          type="number"
          name="number-of-agricultural-employees"
          label="Number of Agricultural Employees (optional)"
          v-model="bizNumberOfAgriculEmployee"
          required
        />

        <base-input
          type="number"
          name="number-of-household-employees"
          label="Number of Household Employees (optional)"
          v-model="bizNumberOfHouseholdEmployee"
          required
        />

        <base-input
          type="number"
          name="number-of-other-employees"
          label="Other Employees (optional)"
          v-model="bizNumberOfOtherEmployee"
          required
        />

      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  data() {
    return {
    }
  },
  computed: {
    bizNeedEinTaxID: {
      get() {
        return store.state.forms.FormTaxInfo.needEinTaxID
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.needEinTaxID', value})
      }
    },
    bizSSN: {
      get() {
        return store.state.forms.FormTaxInfo.bizSSN
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.bizSSN', value})
      }
    },
    bizOwnMotorVehicle55KMore: {
      get() {
        return store.state.forms.FormTaxInfo.hasMotorVehicle55KMore
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.hasMotorVehicle55KMore', value})
      }
    },
    bizInvolveGambling: {
      get() {
        return store.state.forms.FormTaxInfo.involveGambling
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.involveGambling', value})
      }
    },
    bizSellAlcoholTobaccoFirearm: {
      get() {
        return store.state.forms.FormTaxInfo.sellAlcholTobaccoFirearm
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.sellAlcholTobaccoFirearm', value})
      }
    },
    bizPayFedExciseTax: {
      get() {
        return store.state.forms.FormTaxInfo.payFedExciseTax
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.payFedExciseTax', value})
      }
    },
    bizWishAnnualFileUnder4Kwages: {
      get() {
        return store.state.forms.FormTaxInfo.wishAnnualFileUnder4Kwages
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.wishAnnualFileUnder4Kwages', value})
      }
    },
    bizAppliedEinBefore: {
      get() {
        return store.state.forms.FormTaxInfo.appliedEinBefore
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.appliedEinBefore', value})
      }
    },
    bizHireEmployeeIn12Months: {
      get() {
        return store.state.forms.FormTaxInfo.hireEmployeeIn12Months
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.hireEmployeeIn12Months', value})
      }
    },
    bizPreviousFedTaxId: {
      get() {
        return store.state.forms.FormTaxInfo.previousFedTaxId
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.previousFedTaxId', value})
      }
    },
    bizEmpTaxLiability1K: {
      get() {
        return store.state.forms.FormTaxInfo.bizEmpTaxLiability1K
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.bizEmpTaxLiability1K', value})
      }
    },
    bizNumberOfAgriculEmployee: {
      get() {
        return store.state.forms.FormTaxInfo.numberOfAgriculEmployee
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.numberOfAgriculEmployee', value})
      }
    },
    bizNumberOfHouseholdEmployee: {
      get() {
        return store.state.forms.FormTaxInfo.numberOfHouseholdEmployee
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.numberOfHouseholdEmployee', value})
      }
    },
    bizNumberOfOtherEmployee: {
      get() {
        return store.state.forms.FormTaxInfo.numberOfOtherEmployee
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.numberOfOtherEmployee', value})
      }
    },
    bizEmpTaxLiability1KYear: {
      get() {
        return `${new Date(store.state.forms.FormTaxInfo.bizEmpTaxLiability1KFullDate).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTaxInfo.bizEmpTaxLiability1KFullDate)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.bizEmpTaxLiability1KFullDate', value: fullDate})
      }
    },
    bizEmpTaxLiability1KMonth: {
      get() {
        return `${new Date(store.state.forms.FormTaxInfo.bizEmpTaxLiability1KFullDate).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTaxInfo.bizEmpTaxLiability1KFullDate)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.bizEmpTaxLiability1KFullDate', value: fullDate})
      }
    },
    bizEmpTaxLiability1KDate: {
      get() {
        return `${new Date(store.state.forms.FormTaxInfo.bizEmpTaxLiability1KFullDate).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormTaxInfo.bizEmpTaxLiability1KFullDate)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormTaxInfo.bizEmpTaxLiability1KFullDate', value: fullDate})
      }
    }
  }
}
</script>
