<template>
  <div class="step-form mx-auto">
    <div class="mb-2 text-center d-none d-block">
      <h2 class="title ff-arial fs-24 fw-600">Stock your company will be able to issue</h2>
    </div>
    <form>
      <div class="mb-2">
        <slot></slot>
      </div>

      <div class="mb-2">
        <p>The Number of shares authorized is the maximum number of shares that your corporation can issue to the owners. Many of our customers select option an easy round number, such as 10,000.</p>
      </div>

      <base-input
        name="stock-count"
        type="number"
        label="Company Name"
        v-model="stockCount"
        required
      >
        <template v-slot:label><strong># of Authorized Shares</strong></template>
      </base-input>
      
      <div class="mb-2">
        <label for="stock-price" class="form-label"><strong>Share Par Value</strong></label>
        <p>The lowest possible amount you can sell a share for is called the “Par Value”. Shares can be sold for any price as long as it is equal to or greater than this amount. Many corporations select option a very low par value, such as $.001 (one tenth of one cent).</p>
        <input type="number" id="stock-price" name="stock-price" class="form-control" v-model="stockPrice" required>
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  computed: {
    stockCount: {
      get() {
        return store.state.forms.FormStockInfo.stockCount
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormStockInfo.stockCount', value: parseInt(value)})
      }
    },
    stockPrice: {
      get() {
        return store.state.forms.FormStockInfo.stockPrice
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormStockInfo.stockPrice', value: parseInt(value)})
      }
    }
  }
}
</script>
